import React, { useState } from "react";
import {
    IconButton, Box,
    DialogContent, DialogTitle, Dialog,
    Tabs,
    Tab,
    AppBar,
    Tooltip,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
import { VH2Btn } from "./VH2Btn";
import VisibilityIcon from '@mui/icons-material/Visibility';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const VH1Btn = ({ props }) => {
    const [data, setData] = useState('')
    const [tab, setTab] = useState(1)
    const [open, setOpen] = useState(false);
    const onData = () => {
        setData(props)
        setTab(props.etapa - 1)
        setOpen(true)
    }
    return (
        <>
            <Tooltip title="Ver">
                <IconButton size="small" onClick={() => onData()}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    <strong>Ver Informes Técnicos</strong>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ bgcolor: 'action.hover' }}>
                        <AppBar elevation={0} color='inherit'  position="static">
                            <Tabs
                                value={tab}
                                onChange={(e, newValue) => setTab(newValue)}
                            >
                                {data?.informes_id?.map((item, index) => (
                                    <Tab key={index} label={`Informes Tecnicos Etapa ${index + 1}`} />
                                ))}
                            </Tabs>
                        </AppBar>
                        {data?.informes_id?.map((item, index) => (
                            <TabPanel value={tab} index={index} key={index}>
                                <VH2Btn informe={item} />
                            </TabPanel>
                        ))}
                    </Box>
                </DialogContent>
            </Dialog >
        </>
    );
};
