import React, { useState, useRef } from 'react'
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import Swal from 'sweetalert2'
export const UpdateFileManual = ({ instalacion, nameFile, setDataArray }) => {
    const [fileLoading, setFileLoading] = useState(false)
    const fileRef = useRef()
    const [fileName, setFileName] = useState(null)
    const [fileImg, setFileImg] = useState('')
    //
    const [file, setFile] = useState('')
    const handleUploadFileLocal = async (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }
        if (file.type === "application/pdf" || file.type === "image/png" || file.type === "image/jpeg") {
            setFileName(file.name)
            setFile(file)
            const img = URL.createObjectURL(file)
            setFileImg(img)
            //
            setDataArray((prevDataArray) => [...prevDataArray, {
                name: nameFile,
                file: file
            }])
        } else {
            Swal.fire({ icon: "warning", text: "No aceptamos este tipo de archivos", });
        }
    }
    const handleRemove = () => {
        setFileName('')
        setFile('')
        setFileLoading(false)
        setDataArray((prevDataArray) => prevDataArray.filter(item => item.name !== nameFile));
    }
    return (
        <>
            <Box py={2}>
                <Card variant='outlined'>
                    <CardContent>
                    <Typography><strong>{nameFile}</strong></Typography>
                    {fileName ? <Box display='block'>
                        {fileName}
                    </Box> : 'Por favor, cargue el archivo...'}
                    {!file ?
                        <Box>
                            <input
                                onChange={(e) => handleUploadFileLocal(e)}
                                style={{ display: 'none' }}
                                id={`contained-button-file${nameFile}`}
                                multiple
                                type="file"
                                ref={fileRef}
                            />
                            <label htmlFor={`contained-button-file${nameFile}`}>
                                <Button style={{ textTransform: 'none' }} variant="contained" color="primary" disableElevation component="span">
                                    Cargar archivo...
                                </Button>
                            </label>
                        </Box> :
                        <Box display="flex">
                            <Box pr={1}>
                                <Button
                                    disableElevation
                                    variant='outlined'
                                    disabled={fileLoading}
                                    onClick={(e) => handleRemove()}
                                >Borrar archivo</Button>
                            </Box>
                        </Box>}
                        </CardContent>
                </Card>
            </Box>
        </>
    )
}