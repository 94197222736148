import React, { useEffect, useState } from 'react';
import {
    Typography, InputLabel, MenuItem, Stack, Select,
    Card, Box, Container, DialogContent, DialogActions, Button, FormControl, CardContent, Grid
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import InputValidation from '../../components/utils/InputValidation';
import image from '../../assets/img/world_of_ev_solutions.png'
import { db, firebase } from '../../db/firebase'
import Swal from 'sweetalert2';
import moment from 'moment'
export const AsesoriaTecnicaPage = () => {
    const navigate = useNavigate();
    const [cedula, setCedula] = useState({ campo: '', valido: null })
    const [direccion, setDireccion] = useState('')
    const [nombres, setNombres] = useState({ campo: '', valido: null })
    const [whatsapp, setWhatsapp] = useState({ campo: '', valido: null })
    const [correo, setCorreo] = useState({ campo: '', valido: null })
    const [asesoria, setAsesoria] = useState('')
    const [nombreAse, setNomAse] = useState('')
    const [valor, setValor] = useState(0)
    const [loading, setLoading] = useState(false)
    //list
    const [list_assesorias, setListAssesorias] = useState([])
    const [list_pais, setListPais] = useState([])
    useEffect(() => {
        load_list_asistencias()
    }, [])

    const submitHandler = async () => {
        setLoading(true)
        const solicitudesDb = await db.collection("asesoria_tecnica_consultoria_negocios").orderBy("created", "desc").limit(1).get();
        var numeroSolicitud = 1;
        if (solicitudesDb.size > 0) {
            const solicitudes = solicitudesDb.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            const solicitud = solicitudes[0];
            numeroSolicitud = solicitud.id + 1;
        }


        let numInfoFormateado = String(numeroSolicitud).padStart(9, '0')
        let numInfo = `ASES-${numInfoFormateado}`

        await db.collection(`asesoria_tecnica_consultoria_negocios`).doc(numInfo).set({
            id: numeroSolicitud,
            numInfo: numInfo,
            direccion: direccion.campo.toUpperCase(),
            nombres: nombres.campo.toUpperCase(),
            celular: direccion.campo,
            whatsapp: whatsapp.campo,
            email: correo.campo,
            reference_uid: numInfo,
            numeroSolicitud: numeroSolicitud,
            asesoria: nombreAse.toUpperCase(),
            precio: valor,
            estado_pago: 'NO PAGADO',
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        navigate(`/cart/`, {
            state: {
                reference: 'asesoria_tecnica_consultoria_negocios',
                id: numInfo,
                cesta: list_assesorias
            }
        })
        setLoading(false)

   
}
const load_list_asistencias = () => {
    db.collection(`tipos_asesorias`)
        .onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
            setListAssesorias(arrayMessage)
            console.log(arrayMessage)
        })
}
return (
    <>
        <Container maxWidth='sm'>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 25,
                paddingBottom: 10
            }}>
                <img onClick={() => navigate('/')} width={175} src={image} alt='' />
            </div>
            <Box py={2}>
                <Typography align='center' gutterBottom variant='h3'>
                    <strong>Asesorías Tecnicas y Consultoría de Negocios</strong>
                </Typography>
            </Box>
            <Box>
                <InputValidation
                    estado={cedula}
                    cambiarEstado={setCedula}
                    label="Cédula o ruc"
                    helperText="Minimo 10 digitos"
                    patterns={/^\d{10}$/}
                />
                <InputValidation
                    estado={nombres}
                    cambiarEstado={setNombres}
                    type="email"
                    label="Nombres y apellidos"
                    helperText="Solo letras"
                    patterns={/^[a-zA-Z\s]+$/}
                />
                <Grid container spacing={2} >
                    <Grid item md={6}>
                        <InputValidation
                            estado={whatsapp}
                            cambiarEstado={setWhatsapp}
                            label="Numero de telefono"
                            helperText="Minimo 10 digitos"
                            patterns={/^\d{10}$/}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <InputValidation
                            estado={direccion}
                            cambiarEstado={setDireccion}
                            label="Numero de Whatsapp"
                            helperText="Minimo 10 digitos"
                            patterns={/^\d{10}$/}
                        />

                    </Grid>
                </Grid>
                <InputValidation
                    estado={correo}
                    cambiarEstado={setCorreo}
                    type="email"
                    label="Correo electronico"
                    helperText="Solo correo electronico"
                    patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                />
            </Box>
            <Button
                variant='contained'
                color='secondary'
                fullWidth
                disableElevation
                disabled={
                    loading ||
                    !correo.campo || !correo.valido ||
                    !nombres.campo || !nombres.valido ||
                    !whatsapp.campo || !whatsapp.valido
                }
                onClick={() => submitHandler()}
            >Enviar Asesoria Tecnica</Button>
        </Container>
    </>
)
}