import { Chip } from '@mui/material';
export const EstadoEtapa = ({ etapa }) => {
  let color;
  if (etapa == 0) {
    color = 'info'
  } else if (etapa === 1) {
    color = 'success'
  } else if (etapa === 2) {
    color = ''
  }
  return (
    <Chip size='small' label={etapa} color='default' />
  )
}