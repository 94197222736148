import {
    TextField, Typography, Box, Grid, Button, Paper, Alert,
    FormControl, MenuItem, Select, InputLabel, Dialog, Card, CardContent, DialogTitle, DialogContent, Stack, IconButton, DialogActions, Container,
    CircularProgress,
    OutlinedInput,
    ListItemText
} from "@mui/material"
import React, { useEffect, useState } from "react"
import InputValidation from "../../components/utils/InputValidation"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from 'react-redux'
import { auth, firebase, storage, db } from '../../db/firebase'
import { useParams, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import Compressor from 'compressorjs';
import { BorderStyle, CheckBox, UploadFile } from "@mui/icons-material"
import { ButtonBack } from "../../components/utils/ButtonBack"
import { AES, enc } from "crypto-js"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export const CrearOrdenTrabajo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [cedula, setCedula] = useState('')
    const [placa, setPlaca] = useState({ campo: "", valido: true })
    const [ano, setAno] = useState({ campo: "", valido: true })
    const [modelo, setModelo] = useState({ campo: "", valido: true })
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [hev, setHEV] = useState([''])
    const [ecm, setECM] = useState(['']);
    const [dtc, setDTC] = useState([{ text: '', file: null }]);
    const [marca, setMarca] = useState('')
    const [tipoAsesoria, setTipoase] = useState([])
    const [observacion, setObservacion] = useState('')
    const [vehiculo, setVehiculo] = useState('')
    const [tecnico, setTecnico] = useState({ campo: "", valido: true })
    const [list_marcas, setListMarcas] = useState([])
    const [inventario, setInventario] = useState([])
    const [nombres, setNombres] = useState('')
    const [kms, setKms] = useState('')
    const [color, setColor] = useState({ campo: "", valido: true })
    //

    useEffect(() => {
        load_marcas()
        const handleBeforeUnload = (event) => {
            event.returnValue =
                "¿Estás seguro de que quieres abandonar la página? Los datos ingresados se perderán."
        };
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [dispatch])

    const submitHandler = async (e) => {
        setLoading(true);
        try {
            const solicitudesDb = await db.collection("orden_trabajo").orderBy("created", "desc").limit(1).get();
            var numeroSolicitud = 1;
            if (solicitudesDb.size > 0) {
                const solicitudes = solicitudesDb.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                const solicitud = solicitudes[0];
                numeroSolicitud = solicitud.id + 1;
            }
            let dtc_ecm = ecm.filter((input) => input.trim() !== '');
            let dtc_hev = hev.filter((input) => input.trim() !== '');
            let numInfoFormateado = String(numeroSolicitud).padStart(9, '0')
            let numInfo = `ORDEN-${numInfoFormateado}`

            await db.collection('orden_trabajo').doc(numInfo).set({
                id: numeroSolicitud,
                numInfo: numInfo,
                logo: userInfo.picture,
                tipo_vehiculo: vehiculo,
                numeroSolicitud: numeroSolicitud,
                user_uid: auth.currentUser.uid,
                color: color.campo,
                kms: kms,
                inventario: inventario,
                servicios: tipoAsesoria,
                whatsapp: whatsapp,
                email: email,
                //cedula: 'cedula',
                tecnico: tecnico.campo,
                nombres: nombres,
                marca: marca,
                ano: ano.campo,
                placa: placa.campo,
                modelo: modelo.campo,
                observacion_taller: observacion,
                created: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log(numeroSolicitud, ' docRef');
            const uploadPromises = dtc.reverse().map(async (item) => {
                console.log(dtc)
                try {
                    const response = await fetch(item.image);
                    const blob = await response.blob();
                    const compressedResult = await new Promise((resolve, reject) => {
                        new Compressor(blob, {
                            quality: 0.5,
                            success: resolve,
                            error: reject
                        });
                    });
                    const filePath = `orden_trabajo/${numInfo}/${item.name}`
                    const fileRef = storage.ref().child(filePath)
                    await fileRef.put(compressedResult);
                    const downloadURL = await fileRef.getDownloadURL();
                    const updateData = {};
                    updateData[item.name] = downloadURL;
                    db.collection('orden_trabajo').doc(numInfo).update({
                        fotos: firebase.firestore.FieldValue.arrayUnion(downloadURL)
                    });
                } catch (error) {
                    console.log('error 1:', error)
                    return null;
                }
            });
            setLoading(false)
            navigate('/orden/trabajo/list')
            Swal.fire({ icon: 'success', text: 'Informe tecnico subido con éxito' })
        } catch (error) {
            setLoading(false);
            alert(error)
            console.log('error 0:', error)
        }
    };
    const load_marcas = () => {
        db.collection(`system/settings/marcas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListMarcas(arrayMessage)
            })
    }
    const handleInputChange = (value, index) => {
        const updatedecm = [...ecm];
        updatedecm[index] = value;
        setECM(updatedecm);
        if (!updatedecm.some(input => !input)) {
            setECM([...updatedecm, '']);
        }
    };
    const handleImageChange = (event, index) => {
        const file = event.target.files[0];
        console.log(file, 'file')
        console.log(URL.createObjectURL(file), 'file')
        if (file) {
            const updatedInputs = [...dtc];
            updatedInputs[index].image = URL.createObjectURL(file);
            updatedInputs[index].index = `foto_${index}`;
            updatedInputs[index].name = file.name;
            setDTC(updatedInputs);
            console.log(updatedInputs, ' updatedInputs')
            if (!updatedInputs.some(input => !input.image)) {
                setDTC([...updatedInputs, { text: '', file: null }])
            }
        }
        console.log(dtc, 'dtc')
    };
    const handleRemoveInput = (index) => {
        const updatedecm = [...ecm];
        updatedecm.splice(index, 1);
        setECM(updatedecm);
    };
    const handleRemoveHev = (index) => {
        const updatedecm = [...hev];
        updatedecm.splice(index, 1);
        setHEV(updatedecm);
    };
    const handleInputHev = (value, index) => {
        const updatedecm = [...hev];
        updatedecm[index] = value;
        setHEV(updatedecm);
        if (!updatedecm.some(input => !input)) {
            setHEV([...updatedecm, '']);
        }
    };
    const handleRemoveDTC = (index) => {
        const updateddtc = [...dtc];
        updateddtc.splice(index, 1);
        setDTC(updateddtc);
    };
    //

    const list_asesoria = [
        'DIAGNOSTICO COMPUTARIZADO',
        'CHEQUEO SISTEMA HIBRIDO',
        'REPARACION DE BATERIA HV',
        'MATENIMIENTO',
        'REVISION DE CARROCERIA',
    ]
    const list_inventario = [
        'FAROS',
        'GUIAS',
        'PLUMAS',
        'ESPEJOS LATERALES',
        'ANTENA',
        'RADIO',
        'ESPEJO RETROVISOR',
        'A/C',
        'MULTILOCK',
    ]
    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setTipoase(typeof value === 'string' ? value.split(',') : value)
    };
    const handleInventario = (event) => {
        const {
            target: { value }
        } = event;
        setInventario(typeof value === 'string' ? value.split(',') : value)
    };
    return (
        <>
            <Container disableGutters={window.innerWidth <= 767}>
                <Box pl={2} pb={2} pt={5}>
                    <Typography variant="h4" component="h1"><strong>Creación de Orden Trabajo</strong></Typography>
                </Box>
                <Card elevation={2}>
                    <CardContent>
                        <Dialog open={loading}>
                            <Box pt={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>
                            <DialogContent>Enviando Informacion</DialogContent>
                        </Dialog>
                        {/* <Box pb={2}>
                            <InputLabel>
                                Cedula o RUC
                            </InputLabel>
                            <TextField
                                value={cedula}
                                size='small'
                                onChange={(e) => setCedula(e.target.value)}
                            />
                        </Box> */}
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Box pb={2}>
                                    <InputLabel>
                                        Nombre y Apellidos
                                    </InputLabel>
                                    <TextField
                                        value={nombres}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setNombres(e.target.value.toUpperCase())}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Correo Electrónico
                                    </InputLabel>
                                    <TextField
                                        value={email}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Numero de Celular
                                    </InputLabel>
                                    <TextField
                                        value={whatsapp}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setWhatsapp(e.target.value)}
                                    />
                                </Box>

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pb={3}>
                                    <InputLabel>Tipo de Vehiculo</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            fullWidth

                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size='small'
                                            value={vehiculo}
                                            onChange={(e) => setVehiculo(e.target.value)}
                                        >
                                            <MenuItem value="VEHICULO HIBRIDO">VEHICULO HIBRIDO</MenuItem>
                                            <MenuItem value="VEHICULO ELECTRICO">VEHICULO ELECTRICO</MenuItem>
                                            <MenuItem value="VEHICULO DIESEL">VEHICULO DIESEL</MenuItem>
                                            <MenuItem value="VEHICULO GASOLINA">VEHICULO GASOLINA</MenuItem>
                                            <MenuItem value="MOTOS ELECTRICAS">MOTOS ELECTRICAS</MenuItem>
                                            <MenuItem value="BATERIAS">BATERIAS</MenuItem>
                                            <MenuItem value="OTROS">OTROS</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>Marca</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            fullWidth
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size='small'
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        >
                                            {list_marcas?.map(item => (
                                                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Placa
                                    </InputLabel>
                                    <TextField
                                        value={placa.campo}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setPlaca({ campo: e.target.value.toUpperCase(), valido: true })}
                                    />
                                </Box>
                                <InputValidation
                                    estado={modelo}
                                    cambiarEstado={setModelo}
                                    label="Modelo"
                                    placeholder=""
                                    name="name"
                                    helperText="Iniciar con 09 y minimo 10 digitos"
                                />
                                <InputValidation
                                    estado={ano}
                                    cambiarEstado={setAno}
                                    label="Año"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                />
                                <InputValidation
                                    estado={color}
                                    cambiarEstado={setColor}
                                    label="Color"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                />

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Stack direction='column' spacing={2}>
                                    <Box pb={2}>
                                        <InputLabel>
                                            Kms
                                        </InputLabel>
                                        <TextField
                                            value={kms}
                                            size='small'
                                            fullWidth
                                            onChange={(e) => setKms(e.target.value)}
                                        />
                                    </Box>
                                    <InputValidation
                                    estado={tecnico}

                                    cambiarEstado={setTecnico}
                                    label="Tecnico"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                />
                                    <div>
                                        <InputLabel>Servicios</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                multiple
                                                value={tipoAsesoria}
                                                size='small'
                                                onChange={handleChange}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {list_asesoria.map((name, index) => (
                                                    <MenuItem key={index} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <InputLabel>Iventario</InputLabel>
                                        <FormControl fullWidth>
                                            <Select
                                                sx={{
                                                    '& legend': { display: 'none' },
                                                    '& fieldset': { top: 0 },
                                                }}
                                                multiple
                                                value={inventario}
                                                size='small'
                                                onChange={handleInventario}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {list_inventario.map((name, index) => (
                                                    <MenuItem key={index} value={name}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Typography>Revision de Carroceria</Typography>
                                {dtc.map((input, index) => (
                                    <div key={index}
                                        style={{
                                            backgroundColor: 'whitesmoke',
                                            position: 'relative',
                                            display: 'inline-block',
                                            width: '200px',
                                            height: '200px',

                                        }}>
                                        {input.image &&
                                            <img style={{
                                                display: 'block',
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%'
                                            }} src={input.image} alt="Imagen" />
                                        }
                                        <label
                                            htmlFor={`contained-button-file${index}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <input
                                                    onChange={(e) => handleImageChange(e, index)}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    accept=".png, .jpeg, .jpg"
                                                    id={`contained-button-file${index}`}
                                                />
                                                <UploadFile />
                                            </div>
                                        </label>
                                        <div style={{ top: 5, right: 0, position: 'absolute' }}>
                                            <IconButton onClick={() => handleRemoveDTC(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}

                            </Grid>
                            <Grid item md={4} xs={12}>

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pt={2}>
                                    <InputLabel>
                                        Observaciones
                                    </InputLabel>
                                    <TextField
                                        multiline
                                        rows={10}
                                        value={observacion}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setObservacion(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <DialogActions>
                    <ButtonBack />
                    <Button
                        type="submit"
                        variant="contained"
                        color='success'
                        disabled={loading || !observacion || !vehiculo}
                        onClick={() => submitHandler()}
                    > Enviar Informacion
                    </Button>
                </DialogActions>
            </Container>
        </>
    );
};