import React, { useState, useRef } from "react";
import {
    IconButton, Button, Box,
    DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, TextField
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { db, storage, firebase } from "../../db/firebase";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
import EditIcon from '@mui/icons-material/Edit';
import InputValidation from "../../components/utils/InputValidation";
import { UpdateFileManual } from "./UpdateFileManual";
export const BtnManualUpdate = ({ props }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [fileArray, setFileArray] = useState([])
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [descripcion, setDescripcion] = useState({ campo: "", valido: true });
    const [valor, setValor] = useState({ campo: "", valido: true });
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false);
    const handleSubmit = async () => {
        setLoading(true)
        await db.collection('manuales').doc(data.id).update({
            nombre: nombre.campo,
            descripcion: descripcion.campo,
            valor: valor.campo,
            estado: 1,
        })
        const uploadPromises = fileArray.map(async (item) => {
            try {
                const pictureRef = storage.ref().child('manuales').child(`${data.id}`).child(item.name);
                const letreroUpload = pictureRef.put(item.file);
                letreroUpload.on("state_changed", (snapshot) => {
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case firebase.storage.TaskState.PAUSED:
                            console.log('Upload is paused');
                            break;
                        case firebase.storage.TaskState.RUNNING:
                            console.log('Upload is running');
                            break;
                    }
                }, (error) => {
                    Swal.fire({ icon: 'error', text: error, });
                }, async () => {
                    const downloadURL = await letreroUpload.snapshot.ref.getDownloadURL();
                    console.log('File available at', downloadURL, ' ', data.id);
                    const updateData = {};
                    updateData[item.name] = downloadURL;
                    db.collection('manuales').doc(`${data.id}`).update(updateData);
                });
            } catch (error) {
                Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                return null;
            }
        });
        const uploadResults = await Promise.all(uploadPromises);
        const allUploadsSuccessful = uploadResults.every((result) => result !== null);
        if (allUploadsSuccessful) {
            // Todas las subidas fueron exitosas, mostrar el alert
            //alert('Subida de archivos completada con éxito');
            setLoading(false);
        } else {
            setLoading(false);
            alert('Error en la subida de archivos, por favor contactar con soporte');
        }
        setOpen(false)
        Swal.fire({ icon: "success", showConfirmButton: false, text: "El registro se ha actualizado exitosamente.", })
    }
    const onData = () => {
        setData(props)
        console.log(props, 'props')
        setNombre({ campo: props.nombre, valido: true })
        setDescripcion({ campo: props.descripcion, valido: true })
        setValor({ campo: props.valor, valido: true })
        setOpen(true)
    }
    return (
        <>
            <Tooltip title="Editar">
                <Button onClick={() => onData()}>
                    <EditIcon />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    Actualizar
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <InputValidation
                        estado={nombre}
                        cambiarEstado={setNombre}
                        label="Nombre del Manual"
                    />
                    <InputValidation
                        estado={descripcion}
                        cambiarEstado={setDescripcion}
                        label="Descripcion del Manual"
                    />
                    <InputValidation
                        estado={valor}
                        cambiarEstado={setValor}
                        label="Valor del Manual"
                    />
                    <UpdateFileManual
                        setDataArray={setFileArray}
                        nameFile="Portada"
                    />
                    <UpdateFileManual
                        setDataArray={setFileArray}
                        nameFile="Manual"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmit()}
                        color="success"
                        variant='contained'
                        disabled={loading || !valor.campo || !nombre.campo || !descripcion.campo}
                    >
                        Si, aceptar
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};