import React, { useEffect, useState, useRef } from "react";
import {
    IconButton, Box,
    TextField,
    Typography,
    Avatar,
    Button,
    Stack,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { db, firebase } from "../../../db/firebase";
import chagptlogo from '../../../assets/img/robot.png'
import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
function obtenerIdCollecion(loggedin, informe) {
    if (informe && informe.id) {
        return informe.id;
    } else if (loggedin) {
        return loggedin.correo;
    } else {
        return `global`;
    }
}

export const ChatBot1 = ({ informe }) => {
    const navigate = useNavigate();
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [chat, setChat] = useState([]);
    const [message, setMessage] = useState('...');
    const refChat = useRef(null)
    const [subscribed, setSubscribed] = useState(false)
    const [count, setCount] = useState(NaN)
    //let count = getCredits(userInfo)
    console.log('Credits:', count)
    const proms = 'Necesito recomendaciones y conclusiones de estos codigos de fallas'
    const idCollection = obtenerIdCollecion(userInfo, informe)
    useEffect(() => {
        LoadData()
        return () => {
        }
    }, [])
    useEffect(() => {
        chat_permissions()
        getCredits(userInfo)
        console.log(userInfo.credits)
        refChat.current.scrollTop = refChat?.current?.scrollHeight
    }, [message])
    const getCredits = (loggedIn) => {
        if (loggedIn?.credits) {
            setCount(loggedIn?.credits)
            //return loggedIn?.credits;
        } else {
            let storedCount = localStorage.getItem('counter')
            storedCount = (storedCount != null && !isNaN(storedCount)) ? storedCount : 3;
            setCount(storedCount)
            //return parseInt(storedCount);
        }
    }
    const chat_permissions = () => {
        if (count == 0 && !userInfo?.roles?.find(role => ['admin']?.includes(role))) {
            //console.log('no es administrador y no tiene cupo')
            setSubscribed(true)
        }
    }
    const handleInforme = () => {
        if (informe?.id) {
            const stringFromArray = informe.dtc_ecm.join(' ')
            const mess = `${proms} ${stringFromArray}`
            handleSubmit(mess)
        }
    }
    const LoadData = async () => {
        try {
            let ref = db.collection(`evbot/chatbot/${idCollection}`).orderBy("created", "asc");
            ref.onSnapshot((snapshot) => {
                const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                if (!data.some((i) => i.message.includes(proms))) {
                    console.log('NO SE ENCONTRO PROMS');
                    handleInforme();
                }
                setChat(data);
                setMessage('')
            })

            const mess = `¡Hola! 
            Soy EvBot, tu experto virtual en electrónica automotriz.
             
            Estoy aquí para guiarte a través del complejo mundo de los vehículos híbridos y eléctricos
            con mi conocimiento actualizado y asistencia inmediata, aseguro que tus dudas se resuelvan con precisión y eficacia. 
            
            ¡Permíteme ser tu copiloto en esta ruta tecnológica!`;
            const newMessageObject = {
                message: mess,
                uid: "chatgpt",
                created: {
                    seconds: Math.floor(Date.now() / 1000),
                    nanoseconds: (Date.now() % 1000) * 1000000,
                },
                id: `id-${Date.now()}`,
            };

        } catch (error) {
            console.error(error);
        }
    };
    const hackCount = () => {
        localStorage.setItem('counter', 3)
    }
    const handleSubmit = async (message) => {
        setLoading(true)
        await db.collection(`evbot/chatbot/${idCollection}/`).add({
            uid: 'usuario',
            message: message,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        localStorage.setItem('message_chatbot', message);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer sk-proj-Xe9upvLiB288p6dQz6kpT3BlbkFJngwhJDDa2KALxoMykH0P");
        myHeaders.append("Cookie", "__cf_bm=6X21xPgoFTpK2TBqt2SS1OqmhywfG8uJVJYRG9k5hS8-1715044942-1.0.1.1-o2s6oJUMMzZLuWCy5Ptl1dN4EoS3LJ8qd8sg1QnvwJD1YMkFiwNJdrvU__0CHI6blQHf5zQg580SJm1HcmwN4A; _cfuvid=RV2jPpQu8uvTKD_n8DEGkwCejTtL6Kt.2DGC8gk9IcU-1715043584807-0.0.1.1-604800000");

        const raw = JSON.stringify({
            "model": "gpt-3.5-turbo",
            "messages": [
                {
                    "role": "user",
                    "content": message
                }
            ],
            "max_tokens": 400
        });
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);
        const result = await response.text();
        const data = JSON.parse(result);
        //console.log(data.choices[0].message.content)
        //console.log(data)
        await db.collection(`evbot/chatbot/${idCollection}/`).add({
            uid: 'chatgpt',
            message: data.choices[0].message.content,
            created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        const newCount = count - 1
        localStorage.setItem('counter', newCount.toString())
        setMessage('')
        setLoading(false)
        refChat.current.scrollTop = refChat?.current?.scrollHeight
        if (userInfo?.correo && !userInfo?.roles?.find(role => ['admin']?.includes(role))) {
            await db.collection('usuarios').doc(userInfo?.correo).update({
                credits: parseInt(newCount)
            })
        }
    }
    let cesta6 = [
        {
            titulo: '10 Consultas',
            descripcion: 10,
            precio: 10
        },
        {
            titulo: '20 Consultas',
            descripcion: 20,
            precio: 15
        },
        {
            titulo: '50 Consultas',
            descripcion: 50,
            precio: 30
        },
    ]
    return (
        <div>
            <ThemeProvider theme={darkTheme}>
                <Box sx={{
                    backgroundColor: 'background.default',
                    color: 'text.primary',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <div ref={refChat} style={{
                        flex: 'auto',
                        overflowY: 'auto'
                    }}>
                        {
                            !subscribed ? <>
                                <>
                                    {
                                        chat.map((item, index) => (
                                            item.uid !== 'chatgpt' ? (
                                                <div style={{ display: 'flex', padding: 20 }} key={index}>
                                                    <Avatar />
                                                    <div style={{ padding: '5px 10px' }}>
                                                        <Typography>
                                                            <strong>Usted</strong>
                                                        </Typography>
                                                        <Typography>{item.message}</Typography>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ display: 'flex', padding: 20 }} key={index}>
                                                    <Avatar
                                                        src={chagptlogo}
                                                    />
                                                    <div style={{ padding: '5px 10px' }}>
                                                        <Typography>
                                                            <strong>Ev Bot</strong>
                                                        </Typography>
                                                        <div >
                                                            <p style={{ whiteSpace: 'pre-line' }}>{item.message}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        ))
                                    }
                                    {
                                        loading && <div style={{ display: 'flex', padding: 20 }} >
                                            <Avatar
                                                src={chagptlogo}
                                            />
                                            <div style={{ padding: '5px 10px' }}>
                                                <Typography>
                                                    <strong>Ev Bot</strong>
                                                </Typography>
                                                <Typography>Buscando en mis informes tecnicos...</Typography>
                                            </div>
                                        </div>
                                    }


                                </>

                            </> : <div style={{
                                display: 'flex',
                                minHeight: '80vh',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Stack spacing={2} alignItems='center'>
                                    <img src={chagptlogo} alt='' onClick={() => hackCount()} width={100} />
                                    <Typography variant="h5" align="center">
                                        <strong>Ev Bot</strong>
                                    </Typography>
                                    <Typography variant="h6" align="center">¿Quieres informacion de un experto?</Typography>
                                    <Button
                                        align='center'
                                        variant='contained'
                                        sx={{ borderRadius: 10 }}
                                        onClick={() => userInfo?.id ? navigate(`/cart/`, {
                                            state: {
                                                reference: 'usuarios',
                                                id: userInfo.correo,
                                                cesta: cesta6
                                            }
                                        }) : navigate('/account/signup')}
                                    > <Typography variant="h6">Suscribete</Typography>
                                    </Button>
                                </Stack>
                            </div>}
                    </div>
                    <div>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <TextField
                                value={message}
                                style={{ padding: 10 }}
                                //multiline
                                onChange={(e) => setMessage(e.target.value)}
                                variant="outlined"
                                disabled={subscribed}
                                placeholder="Preguntale al experto Ev Bot"
                                fullWidth
                            />

                            <IconButton
                                disabled={!message}
                                color='primary'
                                onClick={() => handleSubmit(message)}
                            >
                                <SendIcon />
                            </IconButton>
                        </Box>
                    </div>
                </Box>
            </ThemeProvider>
        </div>
    );
};
