import React, { useEffect } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import moment from "moment";
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    section: {
        flex: 1
    },
    text: {
        fontSize: 12,
        paddingTop: 5
    }
});
function PDFOrdenTrabajo({ informe }) {
    function convertImageToDataUri(url, callback) {
        return fetch(url)
            .then(response => response.blob())
            .then(blob => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                    reader.readAsDataURL(blob);
                });
            })
            .catch(error => {
                console.error('Error al convertir la imagen:', error);
                throw error;
            });
    }
    return (
        <>
            <Document title={`ORDEN ${informe?.placa} ${moment(new Date().toString()).format('DD MM YYYY h mm ss')}`}>
                <Page wrap size="A4">
                    <View style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30 }}>
                        <View style={{ flexDirection: 'row', paddingBottom: 20 }}>
                            <View style={{ flex: 1 }}>
                                <Image style={{ width: 75, height: 75 }} src={informe?.logo} />
                            </View>
                            <View style={{ flex: 2 }}>
                                <Text style={{ textAlign: 'center' }}>Orden de Trabajo</Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text style={{ textAlign: 'right', fontSize: 12 }}>{informe?.numInfo}</Text>
                            </View>
                        </View>
                        <View style={styles.page}>
                            <View style={styles.section}>
                                <Text style={styles.text}>Señor{`(es)`}: {informe.nombres}</Text>
                                <Text style={styles.text}>Teléfono: {informe.whatsapp}</Text>
                                <Text style={styles.text}>Correo electrónico: {informe.email}</Text>
                                <View style={{ padding: 5 }}></View>
                                <Text style={styles.text}>Marca: {informe.marca}</Text>
                                <Text style={styles.text}>Modelo: {informe.modelo}</Text>
                                <Text style={styles.text}>Año: {informe.ano}</Text>
                                <Text style={styles.text}>Placa: {informe.placa}</Text>
                                <Text style={styles.text}>KMS: {informe.kms}</Text>
                            </View>
                            <View style={styles.section}>
                                <div>
                                    <Text style={{ fontSize: 12, textAlign: 'right', }}>Fecha y hora: </Text>
                                    <Text style={{ fontSize: 12, textAlign: 'right', }}>{moment(informe.created.toDate().toString()).format('DD-MM-YYYY h:mm:ss')}</Text>
                                </div>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', paddingTop: 20, paddingBottom: 20 }}>
                            <View style={{ flex: 1 }}>
                                <Text style={{ fontSize: 12 }}>Servicios:</Text>
                                <View>
                                    {informe?.servicios?.map((item, key) => (
                                        <Text key={key} style={styles.text}>{item}</Text>
                                    ))}
                                </View>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text style={{ fontSize: 12 }}>Inventario:</Text>
                                {informe?.inventario?.map((item, key) => (
                                    <Text key={key} style={styles.text}>{item}</Text>
                                ))}
                            </View>
                        </View>
                        {informe?.observacion_taller &&
                            <View>
                                <Text style={{ fontSize: 12 }}>
                                    Obersvaciones:</Text>
                                <Text style={{ textAlign: 'justify', fontSize: 12 }}>{informe?.observacion_taller}</Text>
                            </View>}
                        <View>
                            {informe?.fotos?.map((item, key) => (
                                <View key={key} style={{ paddingBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Image key={key} style={{ paddingTop: 20, maxWidth: 400, maxHeight: 400 }} src={convertImageToDataUri(item)} />
                                    <Text style={{ textAlign: 'center', fontSize: 12 }}>Imagen {key + 1}</Text>
                                </View>
                            ))}
                        </View>
                    </View>
                    <View break style={{ paddingTop: 30, paddingLeft: 30, paddingRight: 30 }}>
                        {informe?.observacion &&
                            <View>
                                <Text style={{ paddingTop: 20, paddingBottom: 20, textAlign: 'center' }}>
                                    OBSERVACIONES Y CONCLUSIONES</Text>
                                <Text style={{ textAlign: 'justify', fontSize: 12 }}>{informe?.observacion}</Text>
                            </View>}

                        {informe?.precio_informe && <View style={{ paddingTop: 20 }}>
                            <Text style={{ paddingTop: 20 }}>PRECIO SUGERIDO DE INFORME</Text>
                            <Text style={{ paddingTop: 20, fontSize: 12 }}>${informe?.precio_informe}</Text>
                        </View>}
                        {informe?.precio_reparacion && <View style={{ paddingTop: 20 }}>
                            <Text style={{ paddingTop: 20 }}>PRECIO SUGERIDO DE REPARACION</Text>
                            <Text style={{ paddingTop: 20, fontSize: 12 }}>${informe?.precio_reparacion}</Text>
                        </View>}
                        {informe?.logo_revision && <View>
                            <Image style={{
                                width: 200
                            }} src={informe?.logo_revision} />
                        </View>}
                    </View>
                </Page>
            </Document>
        </>
    )
}
export default PDFOrdenTrabajo