import {
    Typography,
    Button,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFInformeTecnico1 from "../../../pages/admin/informe_tecnico/PDFInformeTecnico1";
import moment from "moment";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
export const DescargarInformePDF = ({informe}) => {
    return (
        informe?.numInfo &&
        <PDFDownloadLink
            fileName={`INFORME ${informe?.etapa} ${informe?.placa} ${moment(new Date().toString()).format('DD MM YYYY h mm ss')}`}
            document={<PDFInformeTecnico1 informe={informe}
            />} >
            {({ loading, url, error, blod }) =>
                <Button
                    disableElevation
                    disabled={loading}
                    size='large'
                    startIcon={<FileDownloadIcon />}
                    sx={{ textTransform: 'capitalize' }}
                    color='error' variant='contained'>
                    <Typography>
                        {loading ? <strong>Loading PDF...</strong> : <strong>Download PDF</strong>}
                    </Typography></Button>
            }
        </PDFDownloadLink>
    );
};
