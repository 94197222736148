import Swal from 'sweetalert2'
import { auth } from '../../../db/firebase'
export const authState = (formData) => ({
    type: 'AUTH_SUCCESS',
    payload: {
        uid: formData.uid,
        picture: formData.picture,
        displayName: formData.displayName,
        email: formData.email,
        photoURL: formData.photoURL,
        rol: formData.rol,
        departamento: formData.departamento,
        cargo: formData.cargo,
        ...formData
    }
})
export const login = (formData) => async (dispatch) => {
    try {
        dispatch({ type: 'USER_LOGIN_REQUEST' })
        const sign = await auth.signInWithEmailAndPassword(formData.email, formData.password)
        console.log('datos del long', sign.user.emailVerified)
        //console.log(sign.user.emailVerified)
        //if (sign.user.emailVerified) {
            /* const user = {
                uid: sign.user.uid
            }
            dispatch({
                type: 'USER_LOGIN_SUCCESS',
                payload: user
            }) */
        /* } else {
            Swal.fire({ 
                icon: 'warning', 
                showConfirmButton: false, 
                text: "Por favor, revisa tu correo electrónico y completa el proceso de activación de tu cuenta para acceder." 
            })
        } */
    } catch (error) {
        console.log(error)
        dispatch({
            type: 'USER_LOGIN_FAIL',
            payload: error,
        })
    }
}
export const logout = () => async (dispatch) => {
    dispatch({ type: 'USER_LOGOUT' })
    dispatch({ type: 'AUTH_LOGOUT' })
    await auth.signOut()
}
export const networkingAuth = () => async (dispatch) => {
    try {
        dispatch({ type: 'NETWORKING_AUTH_REQUEST' })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
            "username": "networking",
            "password": "iTcGy3@Wop4"
        })
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        }
        fetch("https://serverapiolt.intercommerce.com.ec/api/token/", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result)
                dispatch({ type: 'NETWORKING_AUTH_SUCCESS', payload: data })
            })
            .catch(error => console.log('error', error));
    } catch (error) {
        dispatch({
            type: 'NETWORKING_AUTH_FAIL',
            payload: error
        })
    }
}