import {
  Paper,
  Chip,
  Box,
  Typography,
  Button,
  Menu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../../db/firebase";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { VH1Btn } from "./VH1Btn";
function Estado(estado) {
  let name;
  let color;
  //Controlamos el estado de la factiblidad
  if (estado.props == 0) {
    name = 'NO PAGADO'
    color = 'warning'
  } else if (estado.props === 1) {
    name = 'PAGADO'
    color = 'error'
  } else if (estado.props === 2) {
    name = 'FACTIBILIDAD'
    color = 'success'
  }
  return (
    <Chip size='small' label={name} color={color} />
  )
}
export const InformeTecnicoVH2 = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth
  const [data, setData] = useState([])
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 1000000000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData()
  }, [startDate])
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("informe_tecnico").orderBy("created", "desc").where("estado", "==", 1);
    if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
      ref = ref.where("user_uid", "==", auth.currentUser.uid)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      ref = ref.where("created", ">=", startfulldate);
      ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      });
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const onChange2 = () => {
    LoadData()
    setAnchorEl(null)
  };
  const [total, setTotal] = useState(0);
  const columns = [
    {
      field: 'created', headerName: 'FECHA DE REGISTRO', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'id', headerName: 'NUMERO DE INFORME', width: 100,
      valueGetter: ({ value }) => `INFO-1-${value}`
    },
    {
      field: 'ETAPA', headerName: 'ETAPA', width: 125,
      renderCell: (params) => {
        return <Chip size='small' label="ETAPA 2" color="info" />
      }
    },
    // {
    //   field: 'ac', headerName: 'ESTADO', width: 125,
    //   renderCell: (params) => {
    //     return <Estado props={params.row.estado} />
    //   }
    // },
    {
      field: 'acciones', headerName: 'ACCIONES', width: 125,
      renderCell: (params) => {
        return <VH1Btn props={params.row} />
      }
    },
    { field: 'nombres', headerName: 'NOMBRE DEL CLIENTE', width: 200 },
    { field: 'whatsapp', headerName: 'CELULAR', width: 125 },
    { field: 'marca', headerName: 'MARCA', width: 100 },
    { field: 'placa', headerName: 'PLACA', width: 100 },
    { field: 'ano', headerName: 'AÑO', width: 100 },
    { field: 'vin', headerName: 'VIN', width: 100 },
    { field: 'tecnico', headerName: 'TECNICO', width: 150 },
    { field: 'observacion', headerName: 'OBSERVACION', width: 150 },
    { field: 'precio', headerName: 'PRECIO', width: 150 },
    
  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `Consultoria de Negocios ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  function CustomFooterTotalComponent(props) {
    return ''
  }
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography variant="subtitle1">
          <strong>({data && data.length})</strong> INFORME TECNICO VEHICULO HIBRIDO
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={(e) => setAnchorEl(e.currentTarget)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box px={1}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Menu>

        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
