import {
  Paper,
  CardContent,
  Box,
  Typography,
  Button,
  Menu,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../../db/firebase";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { VH1Btn } from "../informe_tecnico/VH1Btn";
import { BtnInfoTecOpciones } from "../informe_tecnico/BtnInfoTecOpciones";
import { EstadoPago } from "../../../components/utils/EstadoPago";
import { ViewOrderButton } from "./ViewOrderButton";
import { DeleteButton } from "../../orden_trabajo/DeleteButton";
export const OrdenTrabajoAdminPage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth
  const [data, setData] = useState([])
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 1000000000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData()
  }, [startDate])

  const LoadData = () => {
    let ref = db.collection("orden_trabajo").orderBy("created", "desc")
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setData(data);
    });
  };

  const columns = [
    {
      field: 'created', headerName: 'Fecha de Registro', width: 175, align: 'center', headerAlign: 'center',
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD-MM-YYYY h:mm:ss'),
    },
    { field: 'numInfo', headerName: 'Numero de Informe', align: 'center', headerAlign: 'center', width: 150 },
    {
      field: 'acciones', headerName: 'Opciones', width: 125, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <Box>
           <ViewOrderButton props={params.row} />
           <DeleteButton props={params.row} />
        </Box>
      }
    },
    { field: 'marca', headerName: 'Marca', align: 'center', headerAlign: 'center', width: 100 },
    { field: 'ano', headerName: 'Año', align: 'center', headerAlign: 'center', width: 100 },
   
    { field: 'tipo_vehiculo', headerName: 'Tipo de Vehiculo', align: 'center', headerAlign: 'center', width: 200 },
    { field: 'whatsapp', headerName: 'Celular', align: 'center', headerAlign: 'center', width: 125 },
    { field: 'placa', headerName: 'Placa', align: 'center', headerAlign: 'center', width: 100 },
    { field: 'vin', headerName: 'Vin', align: 'center', headerAlign: 'center', width: 175 },
    { field: 'nombres', headerName: 'Nombre del Taller', align: 'center', headerAlign: 'center', width: 200 },
    { field: 'tecnico', headerName: 'Tecnico', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'observacion', headerName: 'Observacion', align: 'center', headerAlign: 'center', width: 300 },
    { field: 'precio', headerName: 'Precio', align: 'center', headerAlign: 'center', width: 100 },

  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `Informes Tecnios Vehiculos ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  return (
    <Box p={1}>
      <Card elevation={3}>
        <CardContent>
          <Box pb={2}>
            <Typography variant="h4" component="h1"><strong>Orden Trabajo</strong></Typography>
          </Box>
          <Paper>
            <Box sx={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={data}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                columns={columns}
                hideFooter
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
          </Paper>
        </CardContent>
      </Card>
    </Box>
  )
}
