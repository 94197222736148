import React, { useState } from 'react'
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
export const ListRepararVehiculo = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [options, setOptions] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'administracion']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOptions(!options)}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faScrewdriverWrench} />
                    </ListItemIcon>
                    <ListItemText primary="Reparar Vehiculo" />
                    {options ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={options} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                        <ListItemButton component={RouterLink} to={`admin/reparar/vehiculo`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Vehiculo Electrico" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}