import React, { useState, useRef } from "react";
import {
    IconButton, Button,
    DialogActions, DialogContent, DialogTitle, Dialog,  Tooltip
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { db, functions, firebase } from "../../db/firebase";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
import DeleteIcon from '@mui/icons-material/Delete';
export const BtnManualDelete = ({ props }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { loadin, userInfo } = userAuth
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false);
    const handleSubmit = async (id) => {
        db.collection('manuales').doc(data.id).update({
            estado: 0,
        }).then(() => {
            setOpen(false)
            Swal.fire({ icon: "success", showConfirmButton: false, text: "El registro se ha eliminado exitosamente.", })
        })
    }
    const onData = () => {
        setData(props)
        setOpen(true)
    }
    return (
        <>
            <Tooltip title="Eliminar">
                <Button onClick={() => onData()}>
                    <DeleteIcon color='error' />
                </Button>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="sm">
                <DialogTitle>
                    <strong>Eliminar</strong>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    ¿Estas seguro de eliminar el registro {data.nombre}?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmit()}
                        color="error"
                        size='small'
                        variant="outlined"
                    >
                        Si, aceptar
                    </Button>
                    <Button
                        onClick={() => setOpen(false)}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};