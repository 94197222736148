import { Card, Typography, Box, Tab, Container,AppBar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { InformeTecnicoVH1 } from './InformeTecnicoVH1';
import { InformeTecnicoVH2 } from './InformeTecnicoVH2';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
export const InformeVehiculoHibridoPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
    }
  }, [params.id]);
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={5}>
          <Typography variant="h5" component="h1">Informe Tecnico/<strong>Vehiculo Hibrido</strong></Typography>
        </Box>
        <Card>
          <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
            
          <AppBar  elevation={1} position="static">
            <Tabs
              value={tab}
              variant="scrollable"

              scrollButtons
              textColor='inherit'
              allowScrollButtonsMobile
              onChange={(e, newValue) => setTab(newValue)}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 }
                }
              }}
            >
              <Tab label="Informe Tecnico #1" />
              <Tab label="Informe Tecnico #2" />
              <Tab label="Informe Tecnico #3" />
            </Tabs>
            </AppBar>
          </Box>
          <Box>
            <TabPanel value={tab} index={0}>
              <InformeTecnicoVH1 />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <InformeTecnicoVH2 />
            </TabPanel>
            <TabPanel value={tab} index={2}>
            </TabPanel>
            <TabPanel value={tab} index={3}>
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  )
}