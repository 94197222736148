import {
    TextField, Typography, Box, Grid, Button,
    FormControl, MenuItem, Select, InputLabel, Dialog, Card, CardContent, DialogContent, Stack, IconButton, DialogActions, Container,
    CircularProgress
} from "@mui/material"
import React, { useEffect, useState } from "react"
import InputValidation from "../../components/utils/InputValidation"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from 'react-redux'
import { auth, firebase, storage, db } from '../../db/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import Compressor from 'compressorjs';
import { UploadFile } from "@mui/icons-material"
import { ButtonBack } from "../../components/utils/ButtonBack"
import { AES, enc } from "crypto-js"
import { faFileLines, faPen, faPenToSquare, faUserPen } from "@fortawesome/free-solid-svg-icons";
export const CrearInformeTecnico = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams()

    const { action, id } = useParams();
    console.log(params)
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    //
    const [informe, setInforme] = useState('')
    const [placa, setPlaca] = useState('')
    const [ano, setAno] = useState({ campo: "", valido: true })
    const [modelo, setModelo] = useState({ campo: "", valido: true })
    const [vin, setVin] = useState({ campo: "", valido: true })
    const [tecnico, setTecnico] = useState({ campo: "", valido: true })
    const [hev, setHEV] = useState([''])
    const [ecm, setECM] = useState(['']);
    const [dtc, setDTC] = useState([{ text: '', file: '' }]);
    const [marca, setMarca] = useState('')
    const [pdf, setPdf] = useState('')
    const [observacion, setObservacion] = useState('')
    const [vehiculo, setVehiculo] = useState('')
    const [list_marcas, setListMarcas] = useState([])
    const [stepinfo, setStepinfo] = useState(false)
    const [informesId, setInformesid] = useState([])
    //
    const [etapa, setEtapa] = useState(1)
    useEffect(() => {
        load_marcas()
        if (params.id) {

            load_informe(params.id)
        }
        const handleBeforeUnload = (event) => {
            event.returnValue =
                "¿Estás seguro de que quieres abandonar la página? Los datos ingresados se perderán."
        };
        window.addEventListener("beforeunload", handleBeforeUnload)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [dispatch])
    const submitHandler = async (e) => {
        setLoading(true);
        try {
            const solicitudesDb = await db.collection("informe_tecnico").orderBy("created", "desc").limit(1).get();
            var numeroSolicitud = 1;
            if (solicitudesDb.size > 0) {
                const solicitudes = solicitudesDb.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                const solicitud = solicitudes[0];
                numeroSolicitud = solicitud.id + 1;
            }
            let dtc_ecm = ecm.filter((input) => input.trim() !== '');
            let dtc_hev = hev.filter((input) => input.trim() !== '');
            let numInfoFormateado = String(numeroSolicitud).padStart(9, '0')
            let numInfo = `INFO-${numInfoFormateado}`

            await db.collection('informe_tecnico').doc(numInfo).set({
                id: numeroSolicitud,
                numInfo: numInfo,
                etapa: etapa,
                estado_pago: 'NO PAGADO',
                logo: userInfo.picture,
                tipo_vehiculo: vehiculo,
                numeroSolicitud: numeroSolicitud,
                user_uid: auth.currentUser.uid,
                whatsapp: userInfo.whatsapp,
                cedula: userInfo.cedula,
                nombres: userInfo.nombres,
                email: auth.currentUser.email,
                marca: marca,
                informes_id: [
                    ...informesId,
                    numInfo
                ],
                ano: ano.campo,
                vin: vin.campo,
                placa: placa,
                modelo: modelo.campo,
                tecnico: tecnico.campo,
                observacion_taller: observacion,
                dtc_ecm: dtc_ecm,
                dtc_hev: dtc_hev,
                created: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log(numeroSolicitud, ' docRef');
            const uploadPromises = dtc.reverse().map(async (item) => {
                console.log(dtc)
                try {
                    const response = await fetch(item.image);
                    const blob = await response.blob();
                    const compressedResult = await new Promise((resolve, reject) => {
                        new Compressor(blob, {
                            quality: 0.5,
                            success: resolve,
                            error: reject
                        });
                    });
                    const filePath = `informe/${numInfo}/${item.name}`
                    const fileRef = storage.ref().child(filePath)
                    await fileRef.put(compressedResult);
                    const downloadURL = await fileRef.getDownloadURL();
                    //console.log('File available at', downloadURL, ' ', numeroSolicitud);
                    const updateData = {};
                    updateData[item.name] = downloadURL;
                    db.collection('informe_tecnico').doc(numInfo).update({
                        fotos: firebase.firestore.FieldValue.arrayUnion(downloadURL)
                    });
                    //});
                } catch (error) {
                    console.log('error 1:', error)
                    return null;
                }
            });
            //
            if (pdf) {
                const filePath = `informe/${numInfo}/${pdf.name}`
                const fileRef = storage.ref().child(filePath)

                await fileRef.put(pdf);
                const downloadURL = await fileRef.getDownloadURL();
                await db.collection('informe_tecnico').doc(numInfo).update({
                    pdf: downloadURL
                })
            }
            //const uploadResults = await Promise.all(uploadPromises);
            // const allUploadsSuccessful = uploadResults.every((result) => result !== null);
            // if (allUploadsSuccessful) {
            // } else {
            // }
            setLoading(false)
            navigate('/vehiculo/hibrido/list')
            Swal.fire({ icon: 'success', text: 'Informe tecnico subido con éxito' })
        } catch (error) {
            setLoading(false);
            alert(error)
            console.log('error 0:', error)
        }
    };
    const updateHandler = async (e) => {
        setLoading(true);
        try {
            let dtc_ecm = ecm.filter((input) => input.trim() !== '');
            let dtc_hev = hev.filter((input) => input.trim() !== '');

            await db.collection('informe_tecnico').doc(informe.numInfo).set({
                id: informe.id,
                numInfo: informe.numInfo,
                etapa: etapa,
                estado_pago: informe.estado_pago,
                tipo_vehiculo: vehiculo,
                numeroSolicitud: informe.id,
                user_uid: auth.currentUser.uid,
                marca: marca,
                informes_id: [
                    ...informesId,
                    informe.numInfo
                ],
                ano: ano.campo,
                vin: vin.campo,
                placa: placa,
                modelo: modelo.campo,
                tecnico: tecnico.campo,
                observacion_taller: observacion,
                dtc_ecm: dtc_ecm,
                dtc_hev: dtc_hev,
                created: firebase.firestore.FieldValue.serverTimestamp()
            });

            dtc.reverse().map(async (item) => {
                console.log(dtc)
                try {
                    const response = await fetch(item.image);
                    const blob = await response.blob();
                    const compressedResult = await new Promise((resolve, reject) => {
                        new Compressor(blob, {
                            quality: 0.5,
                            success: resolve,
                            error: reject
                        });
                    });
                    const filePath = `informe/${informe.numInfo}/${item.name}`
                    const fileRef = storage.ref().child(filePath)
                    await fileRef.put(compressedResult);
                    const downloadURL = await fileRef.getDownloadURL();
                    const updateData = {};
                    updateData[item.name] = downloadURL;
                    db.collection('informe_tecnico').doc(informe.numInfo).update({
                        fotos: firebase.firestore.FieldValue.arrayUnion(downloadURL)
                    });
                } catch (error) {
                    console.log('error 1:', error)
                    return null;
                }
            });
            if (pdf) {
                const filePath = `informe/${informe.numInfo}/${pdf.name}`
                const fileRef = storage.ref().child(filePath)

                await fileRef.put(pdf);
                const downloadURL = await fileRef.getDownloadURL();
                await db.collection('informe_tecnico').doc(informe.numInfo).update({
                    pdf: downloadURL
                })
            }
            setLoading(false)
            navigate('/informe/list')
            Swal.fire({ icon: 'success', text: 'Informe tecnico subido con éxito' })
        } catch (error) {
            setLoading(false);
            alert(error)
            console.log('error 0:', error)
        }
    };
    const load_marcas = () => {
        db.collection(`system/settings/marcas`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListMarcas(arrayMessage)
            })
    }
    const handleInputChange = (value, index) => {
        const updatedecm = [...ecm];
        updatedecm[index] = value;
        setECM(updatedecm);
        if (!updatedecm.some(input => !input)) {
            setECM([...updatedecm, '']);
        }
    };
    const handleImageChange = (event, index) => {
        const file = event.target.files[0];
        //console.log(file, 'file')
        //console.log(URL.createObjectURL(file), 'file')
        if (file) {
            const updatedInputs = [...dtc];
            updatedInputs[index].image = URL.createObjectURL(file);
            updatedInputs[index].index = `foto_${index}`;
            updatedInputs[index].name = file.name;
            setDTC(updatedInputs);
            //console.log(updatedInputs, ' updatedInputs')
            if (!updatedInputs.some(input => !input.image)) {
                setDTC([...updatedInputs, { text: '', file: null }])
            }
        }
        //console.log(dtc, 'dtc')
    };
    const handleRemoveInput = (index) => {
        const updatedecm = [...ecm];
        updatedecm.splice(index, 1);
        setECM(updatedecm);
    };
    const handleRemoveHev = (index) => {
        const updatedecm = [...hev];
        updatedecm.splice(index, 1);
        setHEV(updatedecm);
    };
    const handleInputHev = (value, index) => {
        const updatedecm = [...hev];
        updatedecm[index] = value;
        setHEV(updatedecm);
        if (!updatedecm.some(input => !input)) {
            setHEV([...updatedecm, '']);
        }
    };
    const handleRemoveDTC = (index) => {
        const updateddtc = [...dtc];
        updateddtc.splice(index, 1);
        setDTC(updateddtc);
    };
    //
    const load_informe = async (id) => {
        try {
            let bytes = AES.decrypt(params.id, '')
            const decrypted = bytes.toString(enc.Utf8)
            console.log('decrypted', decrypted)
            var docRef = db.collection("informe_tecnico").doc(decrypted);
            const doc = await docRef.get();
            if (doc.exists) {
                let info = doc.data()
                console.log('get informe', info)
                setStepinfo(true)
                setMarca(info.marca)
                setInforme(info)
                setPlaca(info.placa)
                setAno({ campo: info.ano, valido: true })
                setModelo({ campo: info.modelo, valido: true })
                setVin({ campo: info.vin, valido: true })
                setTecnico({ campo: info.tecnico, valido: true })
                setVehiculo(info.tipo_vehiculo)
                setEtapa(info.etapa + 1)
                setInformesid(info.informes_id)
                setObservacion(info.observacion_taller)
                //setDTC(info.fotos)
                setECM(info.dtc_ecm)
                setObservacion(info.observacion)
                console.log(info.informes_id, 'infomres id')
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.log("Error getting document:", error);
        }
    }
    return (
        <>
            <Dialog open={loading}>
                <Box pt={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
                <DialogContent>Enviando Informacion</DialogContent>
            </Dialog>
            <Container disableGutters={window.innerWidth <= 767}>
                <Box pl={2} pb={2} pt={5}>
                    <Stack alignContent='center' direction="row" spacing={2}>
                        <FontAwesomeIcon size='2x' icon={action === 'update' ? faPenToSquare : faFileLines} />
                        <Typography variant="h4" component="h1"><strong>
                            {action === 'update' ? 'Editar' : 'Creación de'} Informe Técnico
                        </strong>
                        </Typography>
                    </Stack>
                </Box>
                <Card elevation={2}>
                    <CardContent>
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Box pb={3}>
                                    <InputLabel>
                                        Etapa de Informe
                                    </InputLabel>
                                    <TextField
                                        disabled
                                        value={`N°${etapa}`}
                                        size='small'
                                        fullWidth
                                    />
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>Tipo de Vehiculo</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            fullWidth
                                            disabled={stepinfo}
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size='small'
                                            value={vehiculo}
                                            onChange={(e) => setVehiculo(e.target.value)}
                                        >
                                            <MenuItem value="VEHICULO HIBRIDO">VEHICULO HIBRIDO</MenuItem>
                                            <MenuItem value="VEHICULO ELECTRICO">VEHICULO ELECTRICO</MenuItem>
                                            <MenuItem value="VEHICULO DIESEL">VEHICULO DIESEL</MenuItem>
                                            <MenuItem value="VEHICULO GASOLINA">VEHICULO GASOLINA</MenuItem>

                                            <MenuItem value="MOTOS ELECTRICAS">MOTOS ELECTRICAS</MenuItem>
                                            <MenuItem value="BATERIAS">BATERIAS</MenuItem>
                                            <MenuItem value="OTROS">OTROS</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={3}>
                                    <InputLabel>Marca</InputLabel>
                                    <FormControl fullWidth>
                                        <Select
                                            fullWidth
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size='small'
                                            value={marca}
                                            onChange={(e) => setMarca(e.target.value)}
                                        >
                                            {list_marcas?.map(item => (
                                                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box pb={2}>
                                    <InputLabel>
                                        Placa
                                    </InputLabel>
                                    <TextField
                                        value={placa}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setPlaca(e.target.value.toUpperCase())}
                                    />
                                </Box>
                                {/* <InputValidation
                                    estado={placa}
                                    cambiarEstado={setPlaca}
                                    label="Placa"
                                    name="name"
                                    helperText="Formato correcto 30/03/2002"
                                //patterns={/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/}
                                /> */}
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputValidation
                                    estado={ano}
                                    cambiarEstado={setAno}
                                    label="Año"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                />
                                <InputValidation
                                    estado={modelo}
                                    cambiarEstado={setModelo}
                                    label="Modelo"

                                    placeholder=""
                                    name="name"
                                    helperText="Iniciar con 09 y minimo 10 digitos"
                                //patterns={/^\d{10}$/}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Box pb={2}>
                                    <InputLabel>
                                        VIN
                                    </InputLabel>
                                    <TextField
                                        value={vin.campo}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setVin({ campo: e.target.value.toUpperCase(), valido: true })}
                                    />
                                </Box>
                                <InputValidation
                                    estado={tecnico}
                                    cambiarEstado={setTecnico}
                                    label="Tecnico"
                                    name="name"
                                    helperText="Minimo 8 caracteres"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item md={4} xs={12}>
                                <Typography>Fotos del Vehiculo</Typography>
                                {dtc.map((input, index) => (
                                    <div key={index}
                                        style={{
                                            backgroundColor: 'whitesmoke',
                                            position: 'relative',
                                            display: 'inline-block',
                                            width: '200px',
                                            height: '200px',

                                        }}>
                                        {input.image &&
                                            <img style={{
                                                display: 'block',
                                                objectFit: 'cover',
                                                position: 'absolute',
                                                width: '100%',
                                                height: '100%'
                                            }} src={input.image} alt="Imagen" />
                                        }
                                        <label
                                            htmlFor={`contained-button-file${index}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <input
                                                    onChange={(e) => handleImageChange(e, index)}
                                                    style={{ display: 'none' }}
                                                    type="file"
                                                    accept=".png, .jpeg, .jpg"
                                                    id={`contained-button-file${index}`}
                                                />
                                                <UploadFile />
                                            </div>
                                        </label>
                                        <div style={{ top: 5, right: 0, position: 'absolute' }}>
                                            <IconButton onClick={() => handleRemoveDTC(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}

                            </Grid>
                            <Grid item md={4} xs={12}>
                                <Typography>Ingrese Codigo de Fallas y Alertas</Typography>
                                <Stack spacing={2}>
                                    {ecm.map((input, index) => (
                                        <div key={index}>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    value={input}
                                                    fullWidth
                                                    size='small'
                                                    onChange={(e) => handleInputChange(e.target.value.toUpperCase(), index)}
                                                />
                                                <IconButton onClick={() => handleRemoveInput(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack>
                                        </div>
                                    ))}
                                </Stack>

                            </Grid>
                            <Grid item md={4} xs={12}>
                                {/* <Typography>DTC SISTEMA HEV</Typography>
                                <Stack spacing={2}>
                                    {hev.map((input, index) => (
                                        <div key={index}>
                                            <Stack direction="row" spacing={2}>
                                                <TextField
                                                    value={input}
                                                    fullWidth
                                                    size='small'
                                                    onChange={(e) => handleInputHev(e.target.value, index)}
                                                />
                                                <IconButton onClick={() => handleRemoveHev(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Stack>
                                        </div>
                                    ))}
                                </Stack> */}
                                <Box pt={2}>
                                    <div >
                                        <label htmlFor="fileUpload">Documento Informe Rapido</label>
                                        <input
                                            style={{ padding: '8.5px 14px', borderRadius: '4px', border: '1px solid grey' }}
                                            onChange={(e) => setPdf(e.target.files[0])}
                                            type="file"
                                            accept="pdf/*"
                                            id="fileUpload"
                                        />
                                    </div>
                                </Box>
                                <Box pt={2}>
                                    <InputLabel>
                                        Observaciones
                                    </InputLabel>
                                    <TextField
                                        multiline
                                        rows={10}
                                        value={observacion}
                                        size='small'
                                        fullWidth
                                        onChange={(e) => setObservacion(e.target.value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <DialogActions>
                    <ButtonBack />
                    <Button
                        type="submit"
                        variant="contained"
                        color='success'
                        disabled={loading || !observacion || !vehiculo}
                        onClick={() => action === 'update' ? updateHandler() : submitHandler()}
                    > {action === 'update' ? 'Actualizar' : 'Crear'}  Informacion
                    </Button>
                </DialogActions>
            </Container>
        </>
    );
};