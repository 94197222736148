import React from "react";
import {
    Card, CardActionArea, CardContent, Grid, Typography
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBatteryEmpty, faBook, faCarBattery, faCarRear, faCarSide, faHeadset, faMicroscope, faRobot, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
export const AsesoriasPage = () => {
    const navigate = useNavigate();
    let data = [

        {
            "nombre": "Evaluación de Vehiculo",
            "descripcion": "Permite a los técnicos generar informes detallados sobre reparaciones de baterias",
            "url": "informe/list",
            "id": "BATERY",
            "icon": faCarRear
        }, {
            "nombre": "Ingreso de Vehiculo",
            "descripcion": "Permite a los técnicos generar informes detallados sobre reparaciones vehiculares",
            "url": "orden/trabajo/list",
            "id": "VEHICULO",
            "icon": faCarSide
        },
        {
            "nombre": "Ingreso de Bateria",
            "descripcion": "Permite a los técnicos generar informes detallados sobre reparaciones de baterias",
            "url": "evaluacion/bateria/list",
            "id": "BATERIA",
            "icon": faCarBattery
        },
        {
            "descripcion": "Manuales de Herramientas",
            "id": "Asesoria Tecnica",
            "url": "/manuales",
            "nombre": "Manuales de Herramientas",
            "icon": faBook
        }
        , {
            "descripcion": "Asesoria Tecnica y Consultoria de Negocios",
            "id": "Asesoria Tecnica",
            "url": "/asesoria_tecnica",
            "nombre": "Asesoria Tecnica",
            "icon": faHeadset
        }, {
            "descripcion": "Reparar su Vehiculo Hibrido y Electrico",
            "id": "Asesoria Tecnica",
            "url": "/reparar_vehiculo_hibrido",
            "nombre": "Reparar su Vehiculo",
            "icon": faScrewdriverWrench
        }, {
            "descripcion": "Les permite a los usuarios personalizar su información",
            "id": "EDITAR PERFIL",
            "url": "account/edit",
            "nombre": "EDITAR PERFIL",
            "icon": faUserPen
        }]
    return (
        <Grid container spacing={5}>
            {data?.map((item, index) => (
                <Grid item xs={12} sm={12} md={12} lg={4} key={index}>
                    <Card>
                        <CardActionArea style={{ height: 200 }} onClick={() => navigate(item.url ? item.url : '')}>
                            <CardContent
                                style={{ color: 'inherit', textAlign: 'center', textDecoration: 'none' }}>
                                {
                                    item.url ? <div style={{ alignItems: 'center' }}>
                                        <FontAwesomeIcon  size='4x' icon={item.icon} />
                                    </div> : ''
                                }
                                <Typography gutterBottom textAlign='center' variant="h6">
                                    <strong>{item.nombre}</strong>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">{item.descripcion}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}