import {
    Paper,
    Grid,
    Box
} from "@mui/material";
import logo2 from '../../../assets/img/fondo2.jpeg'
import { ChatBot1 } from "./ChatBot1";
export const ChatBotPage = () => {
    return (
        <Grid container component="main">
            <Grid
                item
                xs={false}
                sm={6}
                md={4}
                sx={{
                    backgroundImage: `url(${logo2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                }}
            >
            </Grid>
            <Grid item xs={12} sm={6} md={8} component={Paper} elevation={6}>
                <ChatBot1 />
            </Grid>
        </Grid>
    )
}