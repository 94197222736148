import {
  Paper,
  Chip,
  Box,
  Typography,
  Button,
  Menu,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../db/firebase";
import { useParams } from 'react-router-dom'
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { BtnManualDelete } from "./BtnManualEliminar";
import { BtnManualUpdate } from "./BtnManualUpdate";

export const ListManuales = () => {
  const params = useParams()
  const [tab, setTab] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth
  const [data, setData] = useState([])
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 172800000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id));
    } else {
      setTab(0)
    }
    LoadData()
  }, [params.id])
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection("manuales").orderBy("created", "desc").where("estado", "==", 1)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      //var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      //var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //ref = ref.where("created", ">=", startfulldate);
      //ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
      ref.onSnapshot((snapshot) => {
        const totalCount = snapshot.size;
        const data = [
          ...persistMessages,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      });
    }
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const onChange2 = () => {
    LoadData()
    setAnchorEl(null)
  };
  const [total, setTotal] = useState(0);
  const columns = [
    {
      field: 'created', headerName: 'FECHA DE REGISTRO', width: 200,
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
    },
    { field: 'nombre', headerName: 'Nombre del Manual', width: 300 },
    { field: 'descripcion', headerName: 'Descripcion del Manual', width: 300 },
    { field: 'valor', headerName: 'Precio', width: 100 },
    {
      field: 'portada', headerName: 'Portada', width: 100,
      renderCell: (params) => {
        console.log(params.row.Portada, ' row')
        return <a target="_blank" href={params.row.Portada}>Ver Portada</a>
      }
    },
    {
      field: 'manual', headerName: 'Manual', width: 100,
      renderCell: (params) => {
        return <a target="_blank" href={params.row.Manual}>Ver Manual</a>
      }
    },
    {
      field: 'acciones', headerName: '', width: 150,
      renderCell: (params) => {
        return (<>
          <BtnManualUpdate props={params.row} />
          <BtnManualDelete props={params.row} />
        </>)
      }
    },

  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `Consultoria de Negocios ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  function CustomFooterTotalComponent(props) {
    return ''
  }
  return (
    <>
      <Box pt={1} pl={1}>
        <Typography variant="subtitle1">
          <strong>({data && data.length})</strong> List de Manuales
        </Typography>
        <Box display="flex" style={{ textTransform: "uppercase" }}>
          <Typography variant="button">
            <strong>Desde</strong>{" "}
            {startDate && moment(startDate.toString()).format("MMMM Do YYYY")}
          </Typography>
          <Typography variant="button" style={{ paddingLeft: 10 }}>
            <strong>Hasta</strong>{" "}
            {endDate && moment(endDate.toString()).format("MMMM Do YYYY")}
          </Typography>
        </Box>
      </Box>
      <Box pl={1} >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center"></Box>
        </Box>
        <Box>
          <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <Button onClick={(e) => setAnchorEl(e.currentTarget)}>Buscar por fecha</Button>
            </Box>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box px={1}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={onChange2}
              >
                Aplicar busqueda
              </Button>
            </Box>
          </Menu>

        </Box>
      </Box>
      <Paper>
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={data ? data : []}
            density="compact"
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooterTotalComponent,
            }}
            onStateChange={(state) => {
              setTotal(state.pagination.rowCount)
            }}
          />
        </Box>
      </Paper>
    </>
  );
};
