import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Typography, Box, Container, Button, Stack, Card, CardContent } from '@mui/material';
import { db, firebase } from '../../../db/firebase'
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
} from '@mui/x-data-grid';
import { EstadoPago } from "../../../components/utils/EstadoPago";
import { EstadoEtapa } from "../../../components/utils/EstadoEtapa";
import moment from 'moment/moment';
import { ViewOrderButton } from './ViewOrderButton';
export const OrdenTrabajoPage = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    var current = new Date();
    const [startDate, setStartDate] = useState(
        new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
        new Date(current.getTime() + 86400000)
    );
    useEffect(() => {
        LoadData();
    }, [startDate]);
    const LoadData = () => {
        let ref = db.collection("orden_trabajo").orderBy("created", "desc")
        ref = ref.where("user_uid", "==", userInfo.id)
        if (endDate && startDate) {
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 0, 0);
            var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
            var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
            //ref = ref.where("created", ">=", startfulldate);
            //ref = ref.where("created", "<=", endfulldate);
            //Si tiene algun doc anterior se agrega acontinuacion
            ref.onSnapshot((snapshot) => {
                const data = [
                    ...snapshot.docs.map((doc) => {
                        return {
                            ...doc.data(),
                            id: doc.id,
                        };
                    }),
                ];
                setData(data);
            });
        }
    };
    const columns = [
        { field: 'numInfo', headerName: 'Numero Informe', align: 'center', headerAlign: 'center', width: 150 },
        {
            field: 'created', headerName: 'Fecha de Registro', width: 150, align: 'center', headerAlign: 'center',
            valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss'),
        },
        {
            field: 'acciones1', headerName: 'Opciones', width: 100, align: 'center', headerAlign: 'center',
            renderCell: (params) => {
                return <ViewOrderButton props={params.row} />
            }
        },
        { field: 'marca', headerName: 'Marca', align: 'center', headerAlign: 'center', width: 100 },
        { field: 'placa', headerName: 'Placa', align: 'center', headerAlign: 'center', width: 100 },
        { field: 'ano', headerName: 'Año', align: 'center', headerAlign: 'center', width: 100 },
        { field: 'vin', headerName: 'Vin', align: 'center', headerAlign: 'center', width: 100 },
        { field: 'nombres', headerName: 'Nombre del Taller', align: 'center', headerAlign: 'center', width: 200 },
        { field: 'tecnico', headerName: 'Tecnico', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'whatsapp', headerName: 'Celular', align: 'center', headerAlign: 'center', width: 125 },
        { field: 'observacion', headerName: 'Observacion', align: 'center', headerAlign: 'center', width: 150 },
        { field: 'precio', headerName: 'Precio', align: 'center', headerAlign: 'center', width: 150 },
    ];
    const csvOptions = {
        delimiter: ';',
        fileName: `ORDEN TRABAJO ${moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss')}`
    };
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={csvOptions} />
            <GridPrintExportMenuItem options={printOptions} />
        </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
        <GridToolbarContainer {...props}>
            <GridToolbarQuickFilter />
            <GridToolbarFilterButton />
            <CustomExportButton />
        </GridToolbarContainer>
    );
    return (
        <>
            <Box pt={5} ></Box>
            <Container disableGutters={window.innerWidth <= 767}>
                <Box>
                    <Typography variant="h4" component="h1"><strong>Ingreso de Vehiculo</strong></Typography>
                </Box>
                <Stack my={2} direction="row" >
                    <Button
                        sx={{ borderRadius: '.3rem', textTransform: 'capitalize' }}
                        size='large'
                        variant="contained"
                        onClick={() => navigate('/orden/trabajo/create')}
                    >
                        <Typography variant='h6'>Crear Ingreso</Typography>
                    </Button>
                </Stack>
                <Card>
                    <CardContent>
                        <Box sx={{ height: 600, width: '100%', bgcolor: 'background.paper' }}>
                            <DataGrid
                                rows={data}
                                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                hideFooter
                                slots={{
                                    toolbar: CustomToolbar,

                                }}
                                onStateChange={(state) => {
                                    setTotal(state.pagination.rowCount)
                                }}
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}