import React, { useEffect, useState } from 'react'
import {
    Typography, InputLabel, MenuItem, CardHeader, Select, Grid, CardActions,
    Card, Box, Container, DialogContent, Button, CardContent, Stack, FormLabel, FormControl
} from '@mui/material'
import StarIcon from '@mui/icons-material/StarBorder';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import InputValidation from '../../components/utils/InputValidation'
import image from '../../assets/img/world_of_ev_solutions.png'
import { db, auth, firebase } from '../../db/firebase'
import Swal from 'sweetalert2'
export const SignupPage = () => {
    const navigate = useNavigate();
    const [cedula, setCedula] = useState({ campo: '', valido: null })
    const [nombres, setNombres] = useState({ campo: '', valido: null })
    const [whatsapp, setWhatsapp] = useState({ campo: '', valido: null })
    const [correo, setCorreo] = useState({ campo: '', valido: null })

    const [asesoria, setAsesoria] = useState('')
    const [loading, setLoading] = useState(false)

    const [password, setPassword] = useState({ campo: '', valido: null })
    const [confirmPassword, setConfirmPassword] = useState({ campo: '', valido: null })
    const [error, setError] = useState({ campo: '', valido: null })
    //list
    const [list_assesorias, setListAssesorias] = useState([])
    const [list_pais, setListPais] = useState([])
    const [list_planes, setListPlanes] = useState([])
    useEffect(() => {
        load_list_asistencias()
        load_pais()
        load_planes()
    }, [])
    const submitHandler = async () => {
        setLoading(true)
        try {
            auth.createUserWithEmailAndPassword(correo.campo, password.campo)
                .then((userCredential) => {
                    const user = userCredential.user;
                    console.log({
                        nombres: nombres.campo.toUpperCase(),
                        cedula: cedula.campo,
                        password: password.campo,
                        whatsapp: whatsapp.campo,
                        correo: correo.campo,
                        verified: false,
                        credits: 3,
                        motivo_registro: asesoria.toUpperCase(),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        roles: [
                            'taller'
                        ]
                    }, ' user')
                    
                    db.collection(`usuarios`).doc(`${correo.campo}`).set({
                        nombres: nombres.campo.toUpperCase(),
                        cedula: cedula.campo,
                        password: password.campo,
                        whatsapp: whatsapp.campo,
                        correo: correo.campo,
                        verified: false,
                        credits: 3,
                        motivo_registro: asesoria.toUpperCase(),
                        created: firebase.firestore.FieldValue.serverTimestamp(),
                        roles: [
                            'taller'
                        ]
                    }).then(() => {
                        user.updateProfile({
                            displayName: nombres.campo
                        }).then(() => {
                            setLoading(false)
                            user.sendEmailVerification()
                            navigate('/')
                            Swal.fire({ icon: "success", showConfirmButton: false, text: "Tu cuenta ha sido creada con éxito, hemos enviado un correo para que confirmes tu cuenta." })
                        })
                    }).catch((error) => {
                        console.log(error)
                        setLoading(false)
                        Swal.fire({ icon: "info", showConfirmButton: false, text: error, })
                        setError(error.message)
                    })
                }).catch((error) => {
                    console.log(error)
                    setLoading(false)
                    Swal.fire({ icon: "info", showConfirmButton: false, text: error, })
                    setError(error.message)
                })
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    const load_list_asistencias = () => {
        db.collection(`tipos_asesorias`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListAssesorias(arrayMessage)
            })
    }
    const load_pais = () => {
        db.collection(`system/settings/pais`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListPais(arrayMessage)
            })
    }
    const load_planes = () => {
        db.collection(`system/settings/planes`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                console.log(arrayMessage, ' array message')
                setListPlanes(arrayMessage)
            })
    }
    const validationPassword = () => {
        if (password.campo.length > 0) {
            if (password.campo !== confirmPassword.campo) {
                setConfirmPassword((prevState) => {
                    return { ...prevState, valido: false }
                });
            } else {
                setConfirmPassword((prevState) => {
                    return { ...prevState, valido: true }
                });
            }
        }
    }
    return (
        <>
            <Container maxWidth='lg'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 25,
                    paddingBottom: 25
                }}>
                    <RouterLink to='/'>
                        <img width={175} src={image} alt='' />
                    </RouterLink>
                </div>
                <Grid container spacing={5}>
                    <Grid item md={6}>
                        <Stack direction="column" spacing={2}>
                            <Box p={5}></Box>
                            <Typography component="h1" variant='h4' align='center' gutterBottom>
                                <strong>¡Bienvenidos a EV Solución!</strong>
                            </Typography>
                            <div>
                                <Typography gutterBottom color='grey' component="h2" variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                                    Nos complace que te unas a nuestra comunidad de
                                    innovación automotriz.
                                </Typography>
                                <Typography gutterBottom color='grey' component="h3" variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                                    Registrese y Aquí, tendrás la oportunidad de realizar
                                    tres consultas gratuitas con EvBot para explorar
                                    nuestras soluciones electrónicas y de reparación
                                    para vehículos híbridos y eléctricos.

                                </Typography>
                                <Typography gutterBottom color='grey' component="h4" variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                                    Si nuestras respuestas cumplen con tus expectativas,
                                    te invitamos a seguir aprovechando nuestros
                                    servicios.

                                </Typography>
                                <Typography gutterBottom color='grey' component="h4" variant='body1' style={{ whiteSpace: 'pre-wrap' }}>
                                    ¡Inicia tu viaje hacia la excelencia en reparación
                                    automotriz con nosotros!
                                </Typography>
                            </div>
                            <div>
                                <Button
                                    sx={{ borderRadius: '2rem', textTransform: 'capitalize' }}
                                    size='large'
                                    color='success'
                                    variant="contained"
                                    onClick={() => navigate('/planes')}
                                >
                                    <Typography variant='h6'>Consulta Planes</Typography>
                                </Button>
                            </div>
                        </Stack>
                    </Grid>
                    <Grid item md={6}>
                        <Box pl={2} pb={2}>
                            <Typography variant='h5'>
                                <strong>Crear Cuenta</strong>
                            </Typography>
                            <Typography variant='subtitle1' color='initial'>Unirte a la Red de Talleres y Laboratorios</Typography>
                        </Box>
                        <Card elevation={2}>
                            <CardContent>
                                <InputValidation
                                    estado={nombres}
                                    cambiarEstado={setNombres}
                                    type="email"
                                    label="Nombres y Apellidos"
                                    helperText="Sólo puede contener letras y espacios"
                                    patterns={/^[a-zA-Z\s]+$/}
                                />
                                <Stack direction="row" spacing={2}>
                                    <InputValidation
                                        estado={cedula}
                                        cambiarEstado={setCedula}
                                        label="Cédula | Ruc | Pasaporte"
                                        helperText="Minimo 10 digitos"
                                        patterns={/^\d{10}$/}
                                    />
                                    <InputValidation
                                        estado={whatsapp}
                                        cambiarEstado={setWhatsapp}
                                        label="Numero de Celular"
                                        helperText="Minimo 10 digitos"
                                        patterns={/^\d{10}$/}
                                    />
                                </Stack>
                                <Box pb={2}>
                                    <InputLabel>Motivo de Registro</InputLabel>
                                    <FormControl fullWidth>

                                        <Select
                                            fullWidth
                                            sx={{
                                                '& legend': { display: 'none' },
                                                '& fieldset': { top: 0 },
                                            }}
                                            size='small'
                                            value={asesoria}
                                            onChange={(e) => setAsesoria(e.target.value)}
                                        >
                                            <MenuItem value="ACTUALMENTE TENGO UN TALLER Y REQUIERO GENERAR NUEVOS SERVICIOS">ACTUALMENTE TENGO UN TALLER Y REQUIERO GENERAR NUEVOS SERVICIOS</MenuItem>
                                            <MenuItem value="QUIERO IMPLEMENTAR UN NUEVO TALLER">QUIERO IMPLEMENTAR UN NUEVO TALLER</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <InputValidation
                                    estado={correo}
                                    cambiarEstado={setCorreo}
                                    type="email"
                                    label="Correo electrónico"
                                    helperText="Solo correos electronicos"
                                    patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                                />
                                <InputValidation
                                    estado={password}
                                    cambiarEstado={setPassword}
                                    type="password"
                                    label="Contraseña"
                                    helperText="Minimo 8 caracteres."
                                    patterns={/^.{8,30}$/}
                                />
                                <InputValidation
                                    estado={confirmPassword}
                                    cambiarEstado={setConfirmPassword}
                                    type="password"
                                    label="Confirmar contraseña"
                                    name="confirmPassword"
                                    helperText="Las contraseñas no coinciden."
                                    funcion={validationPassword}
                                />
                            </CardContent>
                        </Card>
                        <Box pt={2}>
                            <Button
                                variant='contained'

                                fullWidth
                                disabled={
                                    loading ||
                                    !correo.campo || !correo.valido || !asesoria ||
                                    !nombres.campo || !nombres.valido ||
                                    !whatsapp.campo || !whatsapp.valido || confirmPassword.valido === false || confirmPassword.valido === null
                                }
                                onClick={() => submitHandler()}
                            >Crear cuenta</Button>
                        </Box>
                    </Grid>

                </Grid>

                {/* <Box py={5}>
                {list_planes && 
                            <Grid container spacing={5} alignItems="flex-end">
                            {list_planes && list_planes.map((item) => (
                                // Enterprise card is full width at sm breakpoint
                                <Grid
                                    item
                                    key={item.id}
                                    xs={12}
                                    sm={item.title === 'Enterprise' ? 12 : 6}
                                    md={4}
                                >
                                    <Card>
                                        <CardHeader
                                            title={item.nombre}
                                            subheader={item.subheader}
                                            titleTypographyProps={{ align: 'center' }}
                                            action={item.title === 'Pro' ? <StarIcon /> : null}
                                            subheaderTypographyProps={{
                                                align: 'center',
                                            }}
                                            sx={{
                                                backgroundColor: (theme) =>
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.grey[200]
                                                        : theme.palette.grey[700],
                                            }}
                                        />
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                    mb: 2,
                                                }}
                                            >
                                                <Typography component="h2" variant="h3" color="text.primary">
                                                    ${item.precio}
                                                </Typography>
                                                <Typography variant="h6" color="text.secondary">
                                                    /mo
                                                </Typography>
                                            </Box>
                                            <ul>
                                                {item.descripcion.map((line) => (
                                                    <Typography
                                                        component="li"
                                                        variant="subtitle1"
                                                        align="center"
                                                        key={line}
                                                    >
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>
                                        </CardContent>
                                        <CardActions>
                                            <Button fullWidth variant={item.buttonVariant}>
                                              
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>}
                </Box> */}
            </Container>
        </>
    )
}