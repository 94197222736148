import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";
const firebaseConfig = {
    apiKey: "AIzaSyCgPoNRxkAsOkBzaJNbPHWosN-AL9W5qVA",
    authDomain: "ev-soluciones.firebaseapp.com",
    projectId: "ev-soluciones",
    storageBucket: "ev-soluciones.appspot.com",
    messagingSenderId: "362802073592",
    appId: "1:362802073592:web:ddf3a25b52ea218f06900d",
    measurementId: "G-91TMGF9CZD"
}
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();
const functions = firebase.functions();
export { db, auth, firebase, functions, storage };