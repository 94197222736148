import React, { useState } from 'react'
import { ListItemIcon, ListItemButton, Collapse, ListItemText, List } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ExpandLess from '@mui/icons-material/ExpandLess'
import { Link as RouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DescriptionIcon from '@mui/icons-material/Description';
export const ListInformeTecnico = () => {
    const userAuth = useSelector((state) => state.userAuth.userInfo)
    const [options, setOptions] = useState(false)
    return (
        <>
            {userAuth?.roles?.find(role => ['admin', 'administracion']?.includes(role)) ? <>
                <ListItemButton onClick={() => setOptions(!options)}>
                    <ListItemIcon>
                        <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText primary="Informe Tecnico" />
                    {options ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={options} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                        <ListItemButton component={RouterLink} to={`admin/informe/vehiculo`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Vehiculo" />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to={`admin/informe/orden`}>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary="Orden de Trabajo" />
                        </ListItemButton>
                    </List>
                </Collapse>
            </> : ''}
        </>
    )
}