import React, { useContext, useState } from 'react'
import {
    ListItemText, Typography, Box, ListItemIcon, Drawer,
    Toolbar, IconButton, List, ListItem, ListItemButton, Container,
    CardHeader,
    Avatar,
    Divider,
} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import { useSelector, useDispatch } from 'react-redux';
import { ProfileMenu } from './ProfileMenu';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import image from '../../../assets/img/woes_icon.JPG'
import { ListAdministracion } from './opciones/ListAdministracion';
import { ListAsesorias } from './opciones/ListAsesorias';
import { ListRepararVehiculo } from './opciones/ListRepararVehiculo';
import { ListInformeTecnico } from './opciones/ListInformeTecnico';
import { faUserPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListManuales } from './opciones/ListManuales';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));
const drawerWidth = 300;
export const Navbar = (props) => {
  
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
    const { window } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    let theme = createTheme({
        palette: {
            mode: 'dark',
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#333",
                        //color: 'GrayText',
                        boxSizing: "border-box",
                    }
                }
            }
        }
    });
    const roles = userInfo?.roles?.map((e, index) => {
        return e;
    })
    const drawer = (
        <>
            <ThemeProvider theme={theme}>
                <Toolbar style={{ justifyContent: 'center' }}>
                    {/* <img src={image} alt="soei" width="100" /> */}
                </Toolbar>
                <List>
                    <CardHeader
                        avatar={<Avatar src={userInfo?.picture} alt={userInfo?.nombres} />}
                        title={userInfo?.nombres}
                        subheader={roles}
                    />
                    <Divider />
                    <ListItemButton component={RouterLink} to={`/`}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Inicio" />
                    </ListItemButton>
                    <ListInformeTecnico />
                    <ListAsesorias />
                    <ListRepararVehiculo />
                    <ListAdministracion />
                    <ListManuales />
                    <ListItemButton component={RouterLink} to={`/account/edit`}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faUserPen} />
                        </ListItemIcon>
                        <ListItemText primary="Editar perfil" />
                    </ListItemButton>

                    {/* <ListItem >
                        <ListItemText primary={`EV Solutions ${new Date().getFullYear()}`} />
                    </ListItem> */}
                    <ListItem >
                        <ListItemText primary={`Desarollado por Sebastian Mite`} />
                    </ListItem>
                </List>
            </ThemeProvider>
        </>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
        <>
            <Box>
                {
                    props.isAuth && <>
                        <ThemeProvider theme={darkTheme}>
                            <AppBar
                                position="fixed"
                                color='inherit'
                                elevation={2}
                                //variant='outlined'
                                // sx={{
                                //     width: { sm: `calc(100% - ${drawerWidth}px)` },
                                //     ml: { sm: `${drawerWidth}px` },
                                // }}
                                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            >
                                <Toolbar>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={() => setMobileOpen(true)}
                                        edge="start"
                                        sx={{ mr: 2, display: { sm: 'none' } }}
                                    >
                                        <MenuIcon />
                                    </IconButton>

                                    <Typography variant='h5'
                                   
                                        sx={{ cursor: 'pointer', fontStyle: 'oblique' }}
                                        onClick={() => navigate('/')}
                                    >
                                        <strong>EV Solucion</strong>
                                    </Typography>
                                    {/* <img src={image} alt="soei" width="50" /> */}
                                    <Typography variant="h5" noWrap component="div" sx={{ flexGrow: 1 }}>
                                    </Typography>
                                    <ProfileMenu props={userInfo} />
                                </Toolbar>

                            </AppBar>
                        </ThemeProvider>
                        {
                            userInfo?.roles?.find(role => ['admin']?.includes(role)) &&
                            <Box
                                component="nav"
                                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                                aria-label="mailbox folders"
                            >
                                <Drawer
                                    container={container}
                                    variant="temporary"
                                    open={mobileOpen}
                                    onClose={handleDrawerToggle}
                                    ModalProps={{
                                        keepMounted: true, // Better open performance on mobile.
                                    }}
                                    sx={{
                                        display: { xs: 'block', sm: 'none' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                    }}
                                >
                                    {drawer}
                                </Drawer>
                                <Drawer
                                    variant="permanent"
                                    sx={{
                                        display: { xs: 'none', sm: 'block' },
                                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                                    }}
                                    open
                                >
                                    {drawer}
                                </Drawer>
                            </Box>
                        }
                    </>
                }
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        //marginTop: 1,
                        marginLeft: { lg: userInfo?.roles?.find(role => ['admin']?.includes(role)) ? 37.3 : 0 }
                        //width: { sm: `calc(100% - ${drawerWidth}px)` }
                    }}
                >
                    {props.isAuth  &&    location.pathname !== '/chat' && <Toolbar />}
                    {props.children}
                </Box>
            </Box>
        </>
    )
}