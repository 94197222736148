import {
  Box, Card, CardContent, Grid, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../db/firebase";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
  esES
} from '@mui/x-data-grid';
import moment from 'moment'
export const TallerList = () => {
  const [total, setTotal] = useState('');
  const [data, setData] = useState();
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection('talleres')
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setData(data);
    });
  };
  const columns2 = [

    { field: 'nombres', headerName: 'Nombre', width: 250 },
    { field: 'cedula', headerName: 'Cedula', width: 100 },
    { field: 'correo', headerName: 'Correo Electronico', width: 200 },
    { field: 'password', headerName: 'Contraseña', width: 100 },
    { field: 'roles', headerName: 'Roles', width: 300 },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  return (
    <>
      <Grid container spacing={5}>
        {data?.map((item, index) => (
          <Grid item xs={3} sm={3} md={3} lg={3} key={index}>
            <Card sx={{ height: 300 }}>
              <CardContent>
                <img src={item.img} alt='' width='100%' height={100} />
                <Typography gutterBottom textAlign='center' variant="h6">
                  <strong>{item.nombre}</strong>
                  </Typography>
                  <Typography  textAlign='center' variant='body2' color='initial'>{item.direccion}</Typography>
                <Typography  textAlign='center' variant='body2' color='initial'>{item.direccion}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          density="compact"
          rows={data ? data : []}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          columns={columns2}
          hideFooter
          components={{
            Toolbar: CustomToolbar
          }}
          onStateChange={(state) => {
            setTotal(state.pagination.rowCount)
          }}
        >
        </DataGrid>
      </Box> */}
    </>
  );
}