import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
    Grid, Box, ListItemText, Button, Select, FormControl, Checkbox, OutlinedInput,
    MenuItem, Typography, InputLabel, CardContent
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import InputValidation from "../../components/utils/InputValidation";
import { db, functions } from '../../db/firebase';
export const CrearTaller = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [cedula, setCedula] = useState({ campo: '', valido: true })
    const [departamento, setDepartamento] = useState('')
    const [correo, setCorreo] = useState({ campo: '', valido: true })
    const [direccion, setDireccion] = useState({ campo: '', valido: true })
    const [fechaNacimiento, setFechaNacimiento] = useState({ campo: '', valido: true })
    const [proveedor, setProveedor] = useState({ campo: '', valido: true })
    const [nombre, setNombre] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: true })
    const [sangre, setSangre] = useState({ campo: '', valido: true })
    const [genero, setGenero] = useState('')
    const [rol, setRol] = useState([])
    //
    const [roles, setRoles] = useState([])
    const [departamentos, setDepartamentos] = useState([])
    const [loagin, setLoading] = useState(false)
    useEffect(() => {
        loadRoles()
    }, [])

    const loadRoles = () => {
        db.collection('system/settings/roles').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setRoles(arrayMessage)
        })
        db.collection('system/settings/departamentos').onSnapshot((querySnapshot) => {
            const arrayMessage = querySnapshot.docs.map(item => { return item.id })
            setDepartamentos(arrayMessage)
        })
    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setRol(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    //
    const onSubmit = async () => {
        setLoading(true)
        const data = {
            nombres: nombre.campo,
            cedula: cedula.campo,
            direccion: direccion.campo,
            sangre: sangre.campo,
            fechaNacimiento: fechaNacimiento.campo,
            departamento: departamento,
            email: correo.campo,
            password: password.campo,
            roles: rol,
            creado_por: userInfo.uid,
        }
        const createUser = functions.httpsCallable('createUser')
        createUser(data).then((res) => {
            setLoading(false)
            if (res.data.code === 500) {
                alert(res.data.message.errorInfo.message)
            }
            if (res.data.code === 200) {
                Swal.fire({ icon: 'success', text: "Se creo nuevo usuario. en el sistema SOEI" })
            }
        }).catch(error => {
            setLoading(false)
        })
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };
    return (
        <>
            <CardContent>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={6}>
                        <InputValidation
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="NOMBRE DEL TALLER"
                            name="NOMBRE"
                        />
                        <InputValidation
                            estado={correo}
                            cambiarEstado={setCorreo}
                            label="CORREO ELECTRÓNICO"
                            name="correo"
                        />
                        <InputValidation
                            estado={correo}
                            cambiarEstado={setCorreo}
                            label="DIRRECION DE LOCAL"
                            name="correo"
                        />
                        <Box>
                            <InputLabel>
                                ROLES
                            </InputLabel>
                          
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                size='small'
                                fullWidth
                                value={rol}
                                onChange={handleChange}
                                input={<OutlinedInput label="ROLES" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {roles.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={rol.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputValidation
                            estado={cedula}
                            cambiarEstado={setCedula}
                            label="CEDULA/RUC"
                            name="CEDULA"
                        />
                        <InputValidation
                            estado={correo}
                            cambiarEstado={setCorreo}
                            label="TELEFONO"
                            name="correo"
                        />
                        <InputValidation
                            estado={password}
                            cambiarEstado={setPassword}
                            label="CONTRASEÑA"
                            name="password"
                        />
                    </Grid>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" >
                    <Button
                        disabled={loagin}
                        onClick={onSubmit}
                        variant="contained"
                    >Crear Taller
                    </Button>
                </Box>
            </CardContent>
        </>
    );
};
