import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export const ButtonBack = () => {
    const navigate = useNavigate();
    return (
        <Button
            variant='text'
            size='large'
            sx={{ borderRadius: '.3rem', textTransform: 'capitalize' }}
            onClick={() => navigate(-1)}
        > Volver
        </Button>
    )
}