import {
  Box, Chip, CardContent, Card, Paper, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../../db/firebase";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import moment from 'moment'
import { BtnUsuariosOpciones } from "./BtnUsuariosOpciones";
export const UsuariosList = () => {
  const [total, setTotal] = useState('');
  const [data, setData] = useState();
  useEffect(() => {
    LoadData();
  }, []);
  const LoadData = (startAfterDoc, persistMessages = []) => {
    let ref = db.collection('usuarios').orderBy("created", "desc")
    if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
    ref.onSnapshot((snapshot) => {
      const data = [
        ...persistMessages,
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setData(data);
    });
  };
  const columns2 = [
    {
      field: 'created', headerName: 'Fecha y Hora de Registro', width: 175,  align: 'center', headerAlign: 'center',
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD-MM-YYYY h:mm:ss'),
    },
    { field: 'nombres', headerName: 'Nombres y Apellidos',align: 'center', headerAlign: 'center', width: 250 },
    { field: 'cedula', headerName: 'Cedula', align: 'center', headerAlign: 'center',width: 150 },
    { field: 'correo', headerName: 'Correo Electronico', align: 'center', headerAlign: 'center',width: 250 },
    { field: 'verified', headerName: 'Estado Usuario', align: 'center', headerAlign: 'center',width: 125 },
    { field: 'roles', headerName: 'Roles',align: 'center', headerAlign: 'center', width: 100 },
    {
      field: 'acciones', headerName: 'Opciones', align: 'center', headerAlign: 'center',width: 125, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <Box>
           <BtnUsuariosOpciones props={params.row} />
        </Box>
      }
    },
    { field: 'motivo_registro', headerName: 'Motivo de Registro', align: 'center', headerAlign: 'center',width: 400 },
    { field: 'whatsapp', headerName: 'Numero de Whatsapp', align: 'center', headerAlign: 'center',width: 175 },
    { field: 'password', headerName: 'Contraseña', align: 'center', headerAlign: 'center',width: 200 },
  ];
  const csvOptions = { delimiter: ';' };
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  )
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  )
  return (
    <>
      <Box p={1}>
        <Card elevation={3}>
          <CardContent>
            <Box pb={2}>
              <Typography variant="h4" component="h1"><strong>Usuarios</strong></Typography>
            </Box>
            <Paper>
              <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                  density="compact"
                  rows={data ? data : []}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  columns={columns2}
                  hideFooter
                  slots={{
                    Toolbar: CustomToolbar
                  }}
                  onStateChange={(state) => {
                    setTotal(state.pagination.rowCount)
                  }}
                />

              </Box>
            </Paper>
          </CardContent>
        </Card>
      </Box >
    </>
  );
}