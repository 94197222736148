import React, { useState, useEffect } from "react";
import {
    IconButton, Button, Box,
    Tooltip,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { db } from "../../db/firebase";
import Swal from "sweetalert2";
import 'moment/locale/es'
import DeleteIcon from '@mui/icons-material/Delete';
export const DeleteButton = ({ props }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const handleDelete = async () => {
        let id = props.id
        Swal.fire({
            text: "¿Desea eliminar el informe de forma permanente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sí, eliminar"
        }).then((result) => {
       
            if (result.isConfirmed) {
                db.collection('orden_trabajo').doc(id).delete().then(() => {
                    Swal.fire({ icon: "success", showConfirmButton: false, text: "Tu orden trabajo fue eliminado", })
                })
            }
        });
    }
    return (
        <Tooltip title="Borrar">
            <IconButton color='error' size="small" onClick={() => handleDelete()}>
                <DeleteIcon />
            </IconButton>
        </Tooltip>
    );
};