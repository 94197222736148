import { Chip, Typography } from '@mui/material';
export const EstadoPago = ({ estado_pago }) => {
  let color;
  let name;
  if (estado_pago === 'NO PAGADO') {
    color = 'grey'
    name = 'No pagado'
  } else if (estado_pago === 'PAGADO') {
    color = 'green'
    name = 'Pagado'
  } else {
    color = 'grey'
    name = 'Desconocido'
  }
  return (
    <Typography style={{ color: color }}>
      <strong>{name}</strong>
    </Typography>
  )
}