import React, { useState, useEffect } from "react";
import { PDFViewer } from '@react-pdf/renderer';
import 'moment/locale/es'
import PDFInformeTecnico1 from "./PDFInformeTecnico1";
import { db } from '../../../db/firebase'
import { DescargarInformePDF } from "./DescargarInformePDF";
import { } from "@mui/lab";
import { AppBar, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
export const VH2Btn = ({ informe }) => {
    const [data, setData] = useState('')
    const [tab, setTab] = useState(0)
    useEffect(() => {
        load_informe(informe)
    }, [])
    const load_informe = async (id) => {
        try {
            var docRef = db.collection("informe_tecnico").doc(id);
            const doc = await docRef.get();
            if (doc.exists) {
                let info = doc.data()
                //console.log('info', info)
                setData(info)
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.log("Error getting document:", error);
        }
    }
    const handleDownloadClick = async (pdfUrl) => {
        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${data.placa} Diagnosis Vehiculo.pdf`; // Nombre del archivo que se descargará
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error al descargar el PDF:', error);
        }
    };
    return (
        <>
            <Box sx={{ bgcolor: 'action.hover' }}>
                <AppBar color='inherit' elevation={0} position="static">
                    <Tabs
                        value={tab}
                        onChange={(e, newValue) => setTab(newValue)}
                    >
                        <Tab label={`Estado de Salud del Vehiculo`} />
                        <Tab label={`Diagnosis del Vehiculo`} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tab} index={0}>
                    <DescargarInformePDF informe={data} />
                    {
                        data.id && <PDFViewer
                            width='100%'
                            height={1200}
                        >
                            <PDFInformeTecnico1 informe={data} />
                        </PDFViewer>
                    }
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    {
                        data.pdf && <div>
                            <Button
                                disableElevation
                                onClick={() => handleDownloadClick(data?.pdf)}
                                size='large'
                                startIcon={<FileDownloadIcon />}
                                sx={{ textTransform: 'capitalize' }}
                                color='error' variant='contained'>
                                <Typography>
                                    <strong>Download PDF</strong>
                                </Typography></Button>

                            <iframe src={data?.pdf} type="application/pdf" width="100%" height={1200} />
                        </div>
                    }
                </TabPanel>
            </Box>
        </>
    );
};
