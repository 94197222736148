import React, { useState} from "react";
import {
    Button, Typography, Box,
    Grid,
    Stack,
    Container
} from "@mui/material";
import { db, firebase } from "../../db/firebase";
import 'moment/locale/es'
import moment from "moment";
import InputValidation from "../../components/utils/InputValidation";
import { useLocation, useNavigate } from "react-router-dom";
export const PasarelaPagoInfo = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const informe = state
    console.log('props billet', informe)
    const [loading, setLoading] = useState(false)
    const [document, setDocument] = useState({ campo: '', valido: true })
    const [name, setName] = useState({ campo: '', valido: true })
    const [address, setAddress] = useState({ campo: '', valido: true })
    const [email, setEmail] = useState({ campo: '', valido: true })
    const [mobile, setMobile] = useState({ campo: '', valido: true })

    const createPaymentRequest = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer skwjvckrnim182odgm43rw01z27jxpv8g3uaald-jqa2wybhjsl6b2xerqy5vj3mewrws");
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("companyType", "PERSONA NATURAL");
        urlencoded.append("document", document.campo);
        urlencoded.append("documentType", "01");
        urlencoded.append("fullName", name.campo);
        urlencoded.append("address", `${address.campo} ${Math.floor(Math.random() * 100)}`);
        urlencoded.append("mobile", mobile.campo);
        urlencoded.append("email", email.campo);
        urlencoded.append("reference", `${informe.asesoria} ${Math.floor(Math.random() * 100)}`);
        urlencoded.append("description", informe.asesoria);
        urlencoded.append("amount", 1);
        urlencoded.append("amountWithTax", 0);
        urlencoded.append("amountWithoutTax", 1 - 0.15);
        urlencoded.append("tax", 0.15);
        urlencoded.append("gateway", "3");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded
        };
        try {
            const response = await fetch("https://api.abitmedia.cloud/pagomedios/v1/payments/create-payment-request", requestOptions);
            const result = await response.text();
            const ress = JSON.parse(result);
            console.log(ress)
            if (ress.errors) {
                alert(ress.message);
            }
            if (ress.data.url) {
                //const newWindow = window.open(ress.data.url, "", "width=1000,height=900")
                let date = new Date()
                let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
                var id = `${name.campo.toUpperCase()} ${ress.data.token}`;
                await db.collection("abitmedia_tokens").doc(id).set({
                    nombre: name.campo.toUpperCase(),
                    token: ress.data.token,
                    url: ress.data.url,
                    estado_pago: 'NO PAGADO',
                    cedula: document.campo.toUpperCase(),
                    fecha_generada: formattedDate,
                    reference: informe.reference,
                    reference_uid: informe.reference_uid,
                    asesoria: informe.asesoria,
                    valor: 1,
                    //tipo: 'INFORME ETAPA 1',
                    created: firebase.firestore.FieldValue.serverTimestamp()
                });
                navigate('/payment', {
                    state: {
                        ...informe,
                        reference_uid: informe.reference_uid,
                        url: ress.data.url
                    }})
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            alert("No se pudo generar el enlace de pago 0", error);
        }
    }

    return (
        <>
            <Container >
                <Typography sx={{ paddingY: 3 }} variant="h3">
                    <strong>Checkout</strong>
                </Typography>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={7}>
                        <Typography sx={{ paddingY: 3 }} variant="h5">
                            <strong>Detalles de facturación</strong>
                        </Typography>
                        <InputValidation
                            estado={document}
                            cambiarEstado={setDocument}
                            type="text"
                            label="Cédula o Ruc"
                            name="name"
                        //helperText="Sólo puede contener letras y espacios"
                        //={/^[a-zA-ZÀ-ÿ\s]{1,40}$/}
                        />
                        <InputValidation
                            estado={name}
                            cambiarEstado={setName}
                            type="text"
                            label="Nombre o Razon Social"
                            name="name"
                        //helperText="Sólo puede contener letras y espacios"
                        //patterns={/^[a-zA-ZÀ-ÿ\s]{1,40}$/}
                        />
                        <InputValidation
                            estado={mobile}
                            cambiarEstado={setMobile}
                            type="text"
                            label="Teléfono"
                            name="name"
                        //helperText="Solo correo electronico"
                        //patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                        />
                        <InputValidation
                            estado={email}
                            cambiarEstado={setEmail}
                            type="text"
                            label="Correo electrónico"
                            name="name"
                        //helperText="Solo correo electronico"
                        //patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                        />
                        <InputValidation
                            estado={address}
                            cambiarEstado={setAddress}
                            type="text"
                            label="Dirección de domicilio"
                            name="name"
                        //helperText="Sólo puede contener letras y espacios"
                        //patterns={/^[a-zA-ZÀ-ÿ\s]{1,40}$/}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography sx={{ paddingY: 3 }} variant="h5">
                            <strong>Resumen</strong>
                        </Typography>
                        <Stack sx={{ paddingBottom: 5 }} direction="row" justifyContent="space-between">
                            <Typography variant="h6" textAlign='right'>
                                Total:
                            </Typography>
                            <Typography variant="h6" textAlign="left" color='inherit'>${informe.precio.toFixed(2)} USD</Typography>
                        </Stack>
                        <Button
                            disabled={loading || !informe.asesoria || !address.campo}
                            fullWidth
                            disableElevation
                            onClick={() => createPaymentRequest()}
                            variant="contained"
                        >
                            <Box sx={{ textTransform: 'capitalize', marginY: 0.5 }}>
                                <Typography variant="h5">
                                    <strong>Pagar</strong>
                                </Typography>
                            </Box>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};
