import { Card, Typography, Box, Tab, Container, AppBar } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { SolicitudServicioEspera } from '../../solicitudes/SolicitudServicioEspera';

import { useParams, useNavigate } from 'react-router-dom';
import Tabs, { tabsClasses } from '@mui/material/Tabs';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export const ConsultoriaNegocioPage = () => {
  const params = useParams()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
    }
  }, [params.id]);
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={1} pb={3} pt={5}>
          <Typography variant='h5' component="h1"> <strong>Asesoria Tecnica / Consultoria de Negocios</strong></Typography>
        </Box>
        <Card elevation={2}>
          <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
            <AppBar  elevation={1} position="static">
              <Tabs
                value={tab}
                variant="scrollable"
                scrollButtons
                textColor='inherit'
                allowScrollButtonsMobile
                onChange={(e, newValue) => setTab(newValue)}
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 }
                  }
                }}
              >
                <Tab label="Consultoria de Negocios" />
              </Tabs>
            </AppBar>
          </Box>

          <Box>
            <TabPanel value={tab} index={0}>
            
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <SolicitudServicioEspera />
            </TabPanel>
            <TabPanel value={tab} index={2}>
            </TabPanel>
            <TabPanel value={tab} index={3}>
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  )
}