import {
  Paper,
  CardContent,
  Box,
  Typography,
  Button,
  Menu,
  Card,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../../db/firebase";
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { VH1Btn } from "./VH1Btn";
import { BtnInfoTecOpciones } from "./BtnInfoTecOpciones";
import { EstadoPago } from "../../../components/utils/EstadoPago";
import { Description, DescriptionOutlined } from "@mui/icons-material";

export const InformeTecnicoVH1 = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userAuth = useSelector((state) => state.userAuth);
  const { userInfo } = userAuth
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 1000000000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData()
  }, [startDate])

  const LoadData = async () => {
    setLoading(true)
    let ref = db.collection("informe_tecnico").orderBy("created", "desc")
    ref.onSnapshot((snapshot) => {
      const data = [
        ...snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        }),
      ];
      setLoading(false)
      setData(data);
    });
  };

  const columns = [
    {
      headerClassName: 'super-app-theme--header', field: 'created', headerName: 'Fecha de Registro', width: 175, align: 'center', headerAlign: 'center',
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD-MM-YYYY h:mm:ss'),
    },
    { headerClassName: 'super-app-theme--header', field: 'numInfo', headerName: 'Numero de Informe', align: 'center', headerAlign: 'center', width: 150 },
    { headerClassName: 'super-app-theme--header', field: 'etapa', headerName: 'Etapa', align: 'center', headerAlign: 'center', width: 75 },

    {
      headerClassName: 'super-app-theme--header', field: 'ac', headerName: 'Estado', align: 'center', headerAlign: 'center', width: 100,
      renderCell: (params) => {
        return <EstadoPago estado_pago={params.row.estado_pago} />
      }
    },
    { headerClassName: 'super-app-theme--header', field: 'placa', headerName: 'Placa', align: 'center', headerAlign: 'center', width: 100 },
    { headerClassName: 'super-app-theme--header', field: 'modelo', headerName: 'Modelo', align: 'center', headerAlign: 'center', width: 200 },
    { headerClassName: 'super-app-theme--header', field: 'marca', headerName: 'Marca', align: 'center', headerAlign: 'center', width: 100 },
    { headerClassName: 'super-app-theme--header', field: 'dtc_ecm', headerName: 'Codigos', align: 'center', headerAlign: 'center', width: 200 },
    { headerClassName: 'super-app-theme--header', field: 'ano', headerName: 'Año', align: 'center', headerAlign: 'center', width: 100 },
    {
      headerClassName: 'super-app-theme--header', field: 'acciones', headerName: 'Opciones', width: 125, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <Box>
          <VH1Btn props={params.row} />
          <BtnInfoTecOpciones props={params.row} />
          
        </Box>
      }
    },
    { headerClassName: 'super-app-theme--header', field: 'nombres', headerName: 'Nombre del Taller', align: 'center', headerAlign: 'center', width: 200 },
    { headerClassName: 'super-app-theme--header', field: 'tipo_vehiculo', headerName: 'Tipo de Vehiculo', align: 'center', headerAlign: 'center', width: 200 },
    { headerClassName: 'super-app-theme--header', field: 'whatsapp', headerName: 'Celular', align: 'center', headerAlign: 'center', width: 125 },
    { headerClassName: 'super-app-theme--header', field: 'vin', headerName: 'Vin', align: 'center', headerAlign: 'center', width: 175 },
    { headerClassName: 'super-app-theme--header', field: 'tecnico', headerName: 'Tecnico', align: 'center', headerAlign: 'center', width: 150 },
    { headerClassName: 'super-app-theme--header', field: 'observacion', headerName: 'Observacion', align: 'center', headerAlign: 'center', width: 300 },
    { headerClassName: 'super-app-theme--header', field: 'precio', headerName: 'Precio', align: 'center', headerAlign: 'center', width: 100 },
  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `Informes Tecnios Vehiculos ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer sx={{ margin: 1, display: 'flex', justifyContent: 'space-between' }} {...props}>
      <div>
        <CustomExportButton />
      </div>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
  return (
    <Box p={2}>
      <Stack sx={{ margin: 2 }} alignItems="center" direction="row" spacing={1}>
        <Description fontSize='large' />
        <Typography variant="h4" component="h1"><strong>Informes Técnicos</strong></Typography>
      </Stack>
      <Paper>
        <Box
          sx={{
            height: 700,
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#ECECEC',
            },
          }}
        >
          <DataGrid
            rows={data}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            hideFooter
            loading={loading}
            slots={{
              loadingOverlay: LinearProgress,
              //noRowsOverlay: 'No hay informes',
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </Paper>
    </Box>
  )
}
