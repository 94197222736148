import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import {
    Box, Typography,
    Grid, Avatar, Button
} from '@mui/material';
import Compressor from 'compressorjs';
import { storage, firebase, db } from '../../db/firebase'
function ChangePicture() {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [picture, setPicture] = useState(userInfo.picture)
    const submitPicture = async (e) => {
        try {
            const file = e.target.files[0];
            setPicture(URL.createObjectURL(file));
            const compressedFile = await new Promise((resolve, reject) => {
                new Compressor(file, {
                    quality: 0.6,
                    success(compressedFile) {
                        resolve(compressedFile);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
            const storageRef = storage.ref();
            const imageRef = storageRef.child(`usuarios/${userInfo.correo}/picture`);

            await imageRef.put(compressedFile);
            const downloadURL = await imageRef.getDownloadURL();
            await db.collection('usuarios').doc(userInfo.correo).update({
                picture: downloadURL
            });
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
        >
            <Box py={2}>
                <Avatar sx={{ width: 200, height: 200 }} alt={userInfo.username} src={picture} />
            </Box>
            <Typography variant="h5">{userInfo.nombres}</Typography>
            <Typography variant="body2">{userInfo.correo}</Typography>
            <Box py={2}>
                <Button
                    variant="contained"
                    disableElevation
                    component="label"
                    color="primary"
                    onChange={e => submitPicture(e)}
                >
                    Cambiar foto de perfil
                    <input
                        accept="image/*"
                        type="file"
                        hidden
                    />
                </Button>
            </Box>
        </Grid>
    )
}

export default ChangePicture
