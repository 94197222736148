import React, { useState, useEffect } from "react";
import { db } from "../../db/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularLoading } from '../../components/utils/CircularLoading'
import { CrearInformeTecnico } from "./CrearInformeTecnico";
import { AES, enc, SHA256 } from 'crypto-js';
export const ControladorEtapa = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const informe = state
    console.log('informe', informe)
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        setLoading(true)
        try {
            var docRef = db.collection(informe.reference).doc(informe.reference_uid);
            docRef.onSnapshot((doc) => {
                if (doc.exists) {
                    console.log(doc.data())
                    setData(doc.data())
                    if (doc.data().estado_pago == 'PAGADO') {
                        if(informe.reference === 'informe_tecnico'){
                            navigate(`/order/${encodeURIComponent(AES.encrypt(doc.data().reference_uid, ''))}`)
                        }else{
                            navigate('/')
                        }
                      
                    }
                } else {
                    console.log("No such document!");
                }
                setLoading(false)
            })
        } catch (error) {
            console.log("Error getting document:", error);
            setLoading(false)
        }
    }
    return (
        loading ? <CircularLoading /> :
            data?.estado_pago == 'PAGADO' ? <CrearInformeTecnico />: <iframe
                id="iframe"
                frameborder="0"
                style={{ display: 'block', height: '100vh', width: '100vw' }}
                title=""
                width='100%'
                height='100%'
                src={informe.url} /> 
    )
}