import { Card, Typography, Box, Tab, Container } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import { NuevoManual } from './NuevoManual';
import { ListManuales } from './ListManuales';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          {children}
        </Box>
      )}
    </div>
  );
}
export const CargaManualesPage = () => {
  const params = useParams()
  let navigate = useNavigate()
  const [tab, setTab] = useState(0)
  useEffect(() => {
    if (params.id) {
      setTab(parseInt(params.id));
    } else {
      setTab(0)
    }
  }, [params.id]);
  const handleChange = (event, newValue) => {
    setTab(newValue);
    navigate(`/carga_manuales/${newValue}`)
  }
  return (
    <>
      <Container maxWidth='lx' disableGutters={window.innerWidth <= 767}>
        <Box pl={2} pb={2} pt={5}>
          <Typography variant="h5" component="h1">Carga de Manuales</Typography>
        </Box>
        <Card>
          <Box sx={{ maxWidth: { xs: 340, sm: '100%' }, bgcolor: 'background.paper' }}>
            <Tabs
              value={tab}
              variant="scrollable"
         
              scrollButtons
              onChange={handleChange}
              allowScrollButtonsMobile
            
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 }
                }
              }}
            >
              <Tab label="Nuevo Manual" />
              <Tab label="Listado de Manuales" />
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={tab} index={0}>
                <NuevoManual />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ListManuales />
            </TabPanel>
          </Box>
        </Card>
      </Container>
    </>
  )
}