import React, { useState } from "react";
import {
    Typography,
    Box,
    Container,
    Stack,
    Grid,
    Divider,
    Button,
    Checkbox
} from "@mui/material";
import { ButtonBack } from "../../components/utils/ButtonBack";
import { useLocation, useNavigate } from "react-router-dom";
export const CartPage = () => {
    const { state } = useLocation();
    const informe = state
    console.log('props cart', informe)
    const navigate = useNavigate();
    const [checkedItems, setCheckedItems] = useState([]);
    const handleCheckboxChange = (value) => {
        const currentIndex = checkedItems.indexOf(value);
        const newCheckedItems = [...checkedItems];
        if (currentIndex === -1) {
            newCheckedItems.push(value);
        } else {
            newCheckedItems.splice(currentIndex, 1);
        }
        setCheckedItems(newCheckedItems);
    };
    const calculateTotalPrice = () => {
        return checkedItems.reduce((total, index) => total + informe.cesta[index].precio, 0);
    };
    const calculateDescriptions = () => {
        return checkedItems.map(index => informe.cesta[index].descripcion).join(', ');
    };
    const checkout = () => {
        navigate('/checkout', {
            state: {
                ...informe,
                reference_uid: informe.id,
                precio: calculateTotalPrice(),
                asesoria: calculateDescriptions().substring(0, 40)
            }
        })
    }
  
    return (
        <>
            <Container>
                <Typography sx={{ paddingY: 5 }} variant="h3" component="h1"><strong>Carrito</strong></Typography>
                <Grid container spacing={5}>
                    <Grid xs={12} item md={7}>
                        <Typography sx={{ paddingY: 3 }} variant="h5">
                            <strong>Detalles</strong>
                        </Typography>
                        {informe.cesta.map((item, key) => (
                            <Box key={key}>
                                <Divider />
                                <Box py={2} >
                                    <Grid container>
                                        <Grid item xs={1} md={1}>
                                            <Checkbox
                                                checked={checkedItems.includes(key)}
                                                onChange={() => handleCheckboxChange(key)}
                                            />
                                        </Grid>
                                        <Grid item md={10}>
                                            <Typography><strong>{item.titulo}</strong></Typography>
                                            <Typography>{item.descripcion}</Typography>
                                        </Grid>
                                        <Grid item md={1}>
                                            <Typography variant="h6" textAlign='right'> <strong>${item?.precio?.toFixed(2)}</strong></Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>

                        ))}
                    </Grid>
                    <Grid xs={12} item md={5}>
                    <Typography sx={{ paddingY: 3 }} variant="h5">
                            <strong>Detalles de facturación</strong>
                        </Typography>
                        <Stack spacing={2}>
                            <Typography variant="h6">Total</Typography>
                            <Typography variant="h4"> <strong>${calculateTotalPrice().toFixed(2)}</strong></Typography>
                        </Stack>
                        <Button
                            disabled={!calculateTotalPrice()}
                            variant='contained'
                            fullWidth
                            onClick={checkout}
                        ><Box sx={{ textTransform: 'capitalize', marginY: 0.5 }}>
                                <Typography variant="h5">
                                    <strong>Chek out</strong>
                                </Typography>
                            </Box></Button>
                    </Grid>
                </Grid>
                <Box pt={2}>
                    <ButtonBack />
                </Box>
            </Container>
        </>
    );
};
