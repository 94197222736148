import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Container,
    Stack,
    Grid,
    Divider,
    Button,
    Checkbox
} from "@mui/material";
import { AES, enc } from "crypto-js";
import { ButtonBack } from "../../components/utils/ButtonBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "../../db/firebase";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PDFInformeTecnico1 from "../admin/informe_tecnico/PDFInformeTecnico1";
import moment from "moment";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { DescargarInformePDF } from "../admin/informe_tecnico/DescargarInformePDF";
export const OrderInfo = () => {
    const params = useParams()
    const navigate = useNavigate();
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        onLoad()
    }, []);
    const onLoad = async () => {
        setLoading(true)
        try {
            let bytes = AES.decrypt(params.id, '')
            const decrypted = bytes.toString(enc.Utf8)

            var docRef = db.collection("informe_tecnico").doc(decrypted);
            docRef.onSnapshot((doc) => {
                if (doc.exists) {
                    setData(doc.data())
                } else {
                    console.log("No such document!");
                }
                setLoading(false)
            })
        } catch (error) {
            console.log("Error getting document:", error);
            setLoading(false)
        }
    }
    const checkout = () => {
        navigate(`/informe/tecnico/${encodeURIComponent(params.id)}`)
    }
    function convertImageToDataUri(url, callback) {
        fetch('').then(response => response.blob())
            .then(blob => {
                let reader = new FileReader();
                reader.onload = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(blob);
            })
            .catch(error => console.error('Error al convertir la imagen:', error));
    }
    return (
        <Container>
            <Box p={5}></Box>
            <Grid container spacing={5}>
                <Grid xs={12} item md={7}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h1"><strong>{data?.numInfo}</strong></Typography>
                        <Typography variant="h6" color='grey'><strong>Propietario: </strong>{data?.nombres}</Typography>
                        <Typography variant="h6" color='grey'><strong>Vehiculo: </strong>{data?.marca}</Typography>
                        <Typography variant="h6" color='grey'><strong>Modelo: </strong>{data?.modelo}</Typography>
                        <Typography variant="h6" color='grey'><strong>Placa: </strong>{data?.placa}</Typography>
                        <Divider />
                        <Typography variant="h3" ><strong>Observacion</strong></Typography>
                        <Typography variant="h6" color='grey'><strong>Observacion: </strong>{data?.observacion}</Typography>
                        <Typography variant="h6" color='grey'><strong>Precio Sugerido: </strong>${data?.precio}</Typography>
                    </Stack>
                    <Box p={2}></Box>

                </Grid>
                <Grid xs={12} item md={5}>
                    {/*   <Typography sx={{ paddingY: 3 }} variant="h5">
                        <strong>Asesoria</strong>
                    </Typography> fileName={`${data?.numInfo} ${moment(new Date().toString()).format('DD MM YYYY h mm ss')}`}
                  <Stack spacing={2}>
                        <Typography variant="h6">Total</Typography>
                    </Stack> */}
                    <DescargarInformePDF informe={data} />
                    <Box py={2}></Box>
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={checkout}
                    ><Box sx={{ textTransform: 'capitalize', marginY: 0.5 }}>
                            <Typography variant="h5">
                                <strong>Siguiente Etapa #{data?.etapa + 1}</strong>
                            </Typography>
                        </Box></Button>
                </Grid>
            </Grid>
            <Box pt={2}>
                <ButtonBack />
            </Box>
        </Container>
    );
};
