import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, Toolbar, Grid, Card, CardContent, Paper, Stack, Button } from '@mui/material';
import robot from '../../assets/img/robot.png'
import { useNavigate } from 'react-router-dom';
export const PlanesPage = () => {
    const navigate = useNavigate();
    const list_planes = [
        {
            plan: 'Plan Cero',
            precio: 'Gratis',
            descripcion: 'ADQUIERE 3 CONSULTAS GRATIS',
            subtitles: 'Inicie realizando consultas sobre mecanica automotriz',
            cesta: [
                {
                    titulo: 'ADQUIERE 3 CONSULTAS GRATIS',
                    descripcion: '3 Consultas',
                    precio: 3
                }
            ]
        },
        {
            plan: 'Plan Basico',
            precio: 5,
            descripcion: 'ADQUIERE 5 CONSULTAS',
            subtitles: 'Ideal para consultas sobre problemas en electronica automotriz',
            cesta: [
                {
                    titulo: 'ADQUIERE 5 CONSULTAS',
                    descripcion: '5 Consultas',
                    precio: 5
                }
            ]
        },
        {
            plan: 'Plan Estandar',
            precio: 30,
            descripcion: 'ADQUIERE 10 CONSULTAS',
            subtitles: 'Ideal para problemas más complejos o múltiples vehículos.',
            cesta: [
                {
                    titulo: 'ADQUIERE 10 CONSULTAS',
                    descripcion: '10 Consultas',
                    precio: 30
                }
            ]
        },
        {
            plan: 'Plan Premiun',
            precio: 50,
            descripcion: 'ADQUIERE 25 CONSULTAS',
            subtitles: 'Perfecto para talleres y uso profesional intensivo',
            cesta: [
                {
                    titulo: 'ADQUIERE 25 CONSULTAS',
                    descripcion: '25 Consultas',
                    precio: 50
                }
            ]
        },
    ]
    let cesta6 = [
        {
            titulo: '10 Consultas',
            descripcion: '10 Consultas',
            precio: 10
        },
        {
            titulo: '20 Consultas',
            descripcion: '20 Consultas',
            precio: 15
        },
        {
            titulo: '50 Consultas',
            descripcion: '50 Consultas',
            precio: 30
        },
    ]
    return (
        <div style={{
            backgroundColor: '#1A1A40',

        }}>
            <Container>
                <div style={{ paddingBottom: 40, paddingTop: 100, display: 'flex' }}>
                    <h2 style={{ color: 'white' }}>
                        Aquí tienes tres opciones de planes de pago para EvBot, diseñados para adaptarse a tus necesidades:
                    </h2>
                    <img alt='ev bot' src={robot} width={40} height={40} />
                </div>
                <Grid container spacing={5}>
                    {list_planes.map((item, index) => (
                        <Grid item xs={12} md={3} key={index}>
                            <div style={{
                                backgroundColor: '#fff',
                                color: '#000',
                                //height: 500,
                                borderRadius: '10px',
                                padding: '20px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                            }}>
                                <div style={{
                                    backgroundColor: '#3434c1',
                                    borderRadius: '50%',
                                    margin: '-50px auto 20px auto',
                                    width: '200px',
                                    height: '200px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <div>
                                        <h1 style={{ margin: 0, color: '#ffe80f', textAlign: 'center' }}>{item.plan}</h1>
                                        <h1 style={{ margin: 0, color: 'white', textAlign: 'center' }}>${item.precio}</h1>
                                    </div>
                                </div>
                                <h3 style={{ padding: 10, textAlign: 'center', margin: '10px 0' }}>
                                    {item.descripcion}
                                </h3>
                                <h3 style={{ padding: 10, textAlign: 'center', margin: '10px 0' }}>
                                    {item.subtitles}
                                </h3>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        onClick={() => navigate(`/cart/`, {
                                            state: {
                                                reference: 'evbot',
                                                id: 'numInfo',
                                                cesta: item.cesta
                                            }
                                        })}
                                        style={{
                                            backgroundColor: '#82d77d',
                                            color: '#fff',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                        }}>COMPRA AHORA</Button>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <div style={{ paddingBottom: 40, paddingTop: 100 }}>
                    <h3 style={{ color: '#ffe80f' }}>
                        Cada plan está pensado para brindarte la flexibilidad y el soporte necesario para mantener tus vehículos híbridos y eléctricos funcionando a la perfección.
                    </h3>
                </div>
            </Container>
        </div>
    )
}