import React, { useState, useEffect } from "react";
import {
    IconButton, Button, Box,
    DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, TextField,
    Typography,
    Stack,
    FormControl,
    Select,
    MenuItem,
    ListSubheader,
    ListItem,
    List,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Grid,
} from "@mui/material";
import { useSelector } from 'react-redux';
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../db/firebase";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { VH1Btn } from "./VH1Btn";
import { ChatBot1 } from "./ChatBot1";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { Edit } from "@mui/icons-material";
export const BtnInfoTecOpciones = ({ props }) => {
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [data, setData] = useState('')
    const [firma, setFirma] = useState(false);
    const [informes, setInformes] = useState([]);
    const [open, setOpen] = useState(false);
    const [estadoPago, setPago] = useState('NO PAGADO');
    const [observacion, setObservacion] = useState('');
    const [precio, setPrecio] = useState('');
    const [precioReparacion, setReparacion] = useState('');
    const loadCodigo = async (info) => {
        if (info.dtc_ecm.length > 0) {
            const querySnapshot = await db.collection("informe_tecnico")
                .where("dtc_ecm", "array-contains-any", info?.dtc_ecm.slice(0, 10))
                .get();
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            setInformes(data)
            console.log('infomres similares', data);
        }
    }
    const handleSubmit = async () => {
        setOpen(false)
        db.collection('informe_tecnico').doc(data.id).update({
            observacion: observacion ? observacion : '',
            precio_informe: precio ? precio : '',
            precio_reparacion: precioReparacion ? precioReparacion : '',
            estado_pago: estadoPago,
            logo_revision: firma ? userInfo?.picture : '',
        }).then(() => {
            Swal.fire({ icon: "success", showConfirmButton: false, text: "Tu observacion ha sido enviado al Taller", })
        })
    }
    const onData = () => {
        setData(props)
        setObservacion(props?.observacion)
        setPrecio(props?.precio)
        setOpen(true)
        loadCodigo(props)
    }
    const handleDelete = async () => {
        let id = props.id
        Swal.fire({
            text: "¿Desea eliminar el informe de forma permanente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sí, eliminar"
        }).then((result) => {
            console.log(data.id)
            if (result.isConfirmed) {
                db.collection('informe_tecnico').doc(id).delete().then(() => {
                    Swal.fire({ icon: "success", showConfirmButton: false, text: "Tu informe tecnico fue eliminado", })
                })
            }
        });
    }

    return (
        <>
            <Tooltip title="Editar">
                <IconButton color='info'  size="small" onClick={() => onData()}>
                    <Edit />
                </IconButton>
            </Tooltip>
            <Tooltip title="Borrar">
                <IconButton size="small" onClick={() => handleDelete()}>
                    <DeleteOutlineIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth="lg">
                <DialogTitle>
                    <strong>Observacion de Informe</strong>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers >
                    <Grid container spacing={4}>
                        <Grid item lg={6}>
                            <div style={{ height: '10px' }}>
                                <ChatBot1 informe={data} />
                            </div>
                        </Grid>
                        <Grid item lg={6}>
                            <Typography>Informes Tecnicos Similares</Typography>
                            <List dense>
                                {informes.length > 0 ? informes.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        divider
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PictureAsPdfIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={`${item.etapa} ${item.placa}`} secondary={item.dtc_ecm.join(', ')} />
                                        <VH1Btn props={item} />
                                    </ListItem>
                                )) : 'No se encontraron informes similares'}
                            </List>
                            <Box pt={2}>
                           
                                <Stack direction='column' spacing={2}>
                                    <Stack direction='row' spacing={2}>
                                        <div>
                                            <InputLabel>Firmar Informe</InputLabel>
                                            <FormControl variant="outlined">
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={firma}
                                                    onChange={(e) => setFirma(e.target.value)}
                                                >
                                                    <MenuItem value={true}>Si</MenuItem>
                                                    <MenuItem value={false}>No</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <InputLabel>Estado Pago</InputLabel>
                                            <FormControl variant="outlined">
                                                <Select
                                                    sx={{
                                                        '& legend': { display: 'none' },
                                                        '& fieldset': { top: 0 },
                                                    }}
                                                    value={estadoPago}
                                                    onChange={(e) => setPago(e.target.value)}
                                                >
                                                    <MenuItem value="NO PAGADO">NO PAGADO</MenuItem>
                                                    <MenuItem value="PAGADO">PAGADO</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Stack>
                                    <Stack direction='row' spacing={2}>
                                        <div>
                                            <InputLabel>
                                                Precio Sugerido Informe:
                                            </InputLabel>
                                            <TextField

                                                fullWidth
                                                value={precio}
                                                onChange={(e) => setPrecio(e.target.value)}
                                            />
                                        </div>
                                        <div>
                                            <InputLabel>
                                                Precio Sugerido Reparacion:
                                            </InputLabel>
                                            <TextField
                                                fullWidth

                                                value={precioReparacion}
                                                onChange={(e) => setReparacion(e.target.value)}
                                            />
                                        </div>
                                    </Stack>
                                    <div>
                                        <InputLabel>
                                            Observacion:
                                        </InputLabel>
                                        <TextField
                                            fullWidth
                                            multiline
                                            value={observacion}
                                            onChange={(e) => setObservacion(e.target.value)}
                                            rows={10}
                                        />
                                    </div>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="success"
                        disableElevation
                        onClick={() => handleSubmit()}
                        variant="contained"
                    >
                        Guardar Observacion
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
