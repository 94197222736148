import React, { useEffect, useState } from 'react';
import {
    Typography, InputLabel, MenuItem, Stack, Select,
    Card, Box, Container, CardActions, DialogActions, Button, FormControl, CardContent, Grid, CardMedia
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import image from '../../assets/img/world_of_ev_solutions.png'
import { db, firebase } from '../../db/firebase'
import moment from 'moment'
export const ManualesPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    //list
    const [list_manuales, setListManuales] = useState([])
    useEffect(() => {
        load_manuales()
    }, [])
    const load_manuales = () => {
        db.collection(`manuales`).where("estado", "==", 1)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListManuales(arrayMessage)
            })
    }
    const submitHandler = (item) => {
        setLoading(true)
        //Abitmedia
        let datos = {
            cedula: '0953864782',
            nombres: 'TEST USUARIO PREUAB',
            correo: 'sebastian_mtie@hotmail.com',
            whatsapp: '0953864782',
        
            direccion: 'EL ORO Y GARCIA MORENO'
        }
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer skwjvckrnim182odgm43rw01z27jxpv8g3uaald-jqa2wybhjsl6b2xerqy5vj3mewrws");
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("companyType", "PERSONA NATURAL");
        urlencoded.append("document", datos.cedula);
        urlencoded.append("documentType", "01");
        urlencoded.append("fullName", datos.nombres);
        urlencoded.append("address", `${datos.direccion} ${Math.floor(Math.random() * 100)}`);
        urlencoded.append("mobile", datos.whatsapp);
        urlencoded.append("email", datos.correo);
        urlencoded.append("reference", `${datos.direccion} ${Math.floor(Math.random() * 100)}`);
        urlencoded.append("description", item.descripcion);
        urlencoded.append("amount", item.valor);
        urlencoded.append("amountWithTax", 0);
        urlencoded.append("amountWithoutTax", item.valor - 1);
        urlencoded.append("tax", 1);
        urlencoded.append("gateway", "3");
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded
        };
        fetch("https://api.abitmedia.cloud/pagomedios/v1/payments/create-payment-request", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                const data = JSON.parse(result);
                if (data.errors) {
                    alert(data.message)
                }
                if (data.data.url) {
                    //Success
                    let newWindow = window.open(data.data.url, "", "width=900,height=900")
                    //let htmlElement = newWindow.document.documentElement.innerHTML;
                    console.log(data.data.url)
                    let date = new Date()
                    let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
                    //
                    var id = `${datos.nombres.toUpperCase()} ${data.data.token}`
                    db.collection("abitmedia_tokens").doc(id).set({
                        //nombre: nombres.campo.toUpperCase(),
                        token: data.data.token,
                        url: data.data.url,
                        estado: 0,
                        //cedula: datos.cedula.toUpperCase(),
                        fecha_generada: formattedDate,
                        valor: item.valor,
                        tipo: 'Asesorías Tecnicas y Consultoría de Negocios',
                        //tipos_asesoria: nombreAse.toUpperCase(),
                        created: firebase.firestore.FieldValue.serverTimestamp()
                    })
                    //
                    // db.collection('asesoria_tecnica_consultoria_negocios').orderBy('created', 'desc').limit(1).get()
                    //     .then((transaccionesDb) => {
                    //         let id_transaccion = 1
                    //         if (!transaccionesDb.empty) {
                    //             const ultimoDocumento = transaccionesDb.docs[0]
                    //             id_transaccion = ultimoDocumento.data().id
                    //         }
                    //         const nuevoID = id_transaccion + 1
                    //         db.collection(`asesoria_tecnica_consultoria_negocios`).doc(`${nuevoID}`).set({
                    //             id: nuevoID,
                    //             direccion: direccion.campo.toUpperCase(),
                    //             nombres: nombres.campo.toUpperCase(),
                    //             celular: whatsapp.campo,
                    //             correo: correo.campo,
                    //             tipos_asesoria: nombreAse.toUpperCase(),
                    //             valor: valor,
                    //             pagado: 0,
                    //             created: firebase.firestore.FieldValue.serverTimestamp(),
                    //         }).then(() => {
                    //             setLoading(false)
                    //         })
                    //     })
                }
                console.log("Abitmedia", data);
            })
            .catch((error) => console.log("error", error));

    }
    return (
        <>
            <Container maxWidth='lg'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 25,
                    paddingBottom: 10
                }}>
                    <RouterLink to='/'>
                        <img width={175} src={image} alt='' />
                    </RouterLink>
                </div>
                <Box py={5} pl={2} >
                    <Typography gutterBottom variant='h3'>
                        <strong>Manuales de Diagnosticos</strong>
                    </Typography>
                </Box>
                <Grid container spacing={5}>
                    {list_manuales?.map((item, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                            <Card>
                                <CardMedia
                                    sx={{ height: 200 }}
                                    image={item.Portada}
                                    title={item.nombre}
                                />
                                <CardContent sx={{ height: '10%' }}
                                    style={{ color: 'inherit', textAlign: 'center', textDecoration: 'none' }}>
                                    <Typography textAlign='left' variant="h5">
                                        <strong>{item.nombre}</strong>
                                    </Typography>
                                    <Typography textAlign='left' color='initial'>{item.descripcion}</Typography>
                                    <Typography textAlign='left' color='initial'>${item.valor}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => submitHandler(item)} fullWidth variant='contained'>Comprar</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Box py={5}></Box>
        </>
    )
}