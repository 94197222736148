import {
    Paper,
    Chip,
    Box,
    Typography,
    Button,
    Menu,
    Card,
    CardContent,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import moment from "moment";
  import { auth, db, firebase } from "../../../db/firebase";
  import { useSelector } from "react-redux";
  import DatePicker from "react-datepicker";
  import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridPrintExportMenuItem,
    GridToolbarQuickFilter,
    esES
  } from '@mui/x-data-grid';
  import { EstadoPago } from "../../../components/utils/EstadoPago";
  function Estado(estado) {
    let name;
    let color;
    //Controlamos el estado de la factiblidad
    if (estado.props == 0) {
      name = 'NO PAGADO'
      color = 'warning'
    } else if (estado.props === 1) {
      name = 'PAGADO'
      color = 'error'
    } else if (estado.props === 2) {
      name = 'FACTIBILIDAD'
      color = 'success'
    }
    return (
      <Chip size='small' label={name} color={color} />
    )
  }
  export const RepararVehiculoList = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userAuth = useSelector((state) => state.userAuth);
    const { loading, userInfo } = userAuth
    const [data, setData] = useState([])
    var current = new Date();
    const [startDate, setStartDate] = useState(
      new Date(current.getTime() - 172800000)
    );
    const [endDate, setEndDate] = useState(
      new Date(current.getTime() + 86400000)
    );
  
    useEffect(() => {
      LoadData()
    }, [startDate])
    const LoadData = (startAfterDoc, persistMessages = []) => {
      let ref = db.collection("reparar_vehiculo_hibrido").orderBy("created", "desc")
      if (userInfo?.roles?.find(role => ['operador']?.includes(role)))
        ref = ref.where("user_uid", "==", auth.currentUser.uid)
      if (endDate && startDate) {
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 0, 0);
        var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
        var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
        //ref = ref.where("created", ">=", startfulldate);
        //ref = ref.where("created", "<=", endfulldate);
        //Si tiene algun doc anterior se agrega acontinuacion
        if (startAfterDoc) ref = ref.startAfter(startAfterDoc);
        ref.onSnapshot((snapshot) => {
          const totalCount = snapshot.size;
          const data = [
            ...persistMessages,
            ...snapshot.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              };
            }),
          ];
          setData(data);
        });
      }
    };
    const onChange = (dates) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
    const onChange2 = () => {
      LoadData()
      setAnchorEl(null)
    };
    const [total, setTotal] = useState(0);
    const columns = [
      {
        field: 'created', headerName: 'Fecha de Registro', width: 200,
        valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY, h:mm:ss a'),
      },
      { field: 'id', headerName: 'Numero de Asesoria', width: 150 },
      {
        field: 'ac', headerName: 'Estado', width: 125,
        renderCell: (params) => {
          return <EstadoPago estado_pago={params.row.estado_pago} />
        }
      },
      { field: 'nombres', headerName: 'Nombre del Cliente', width: 300 },
      { field: 'asesoria', headerName: 'Tipo de Asesoria', width: 200 },
      { field: 'celular', headerName: 'Numero de Celular', width: 150 },
      { field: 'email', headerName: 'Correo Electronico', width: 250 },
      { field: 'precio', headerName: 'Precio', width: 75 },
      { field: 'direccion', headerName: 'Direccion de Domicilio', width: 200 },
    ];
    const csvOptions = {
      delimiter: ';',
      fileName: `Consultoria de Negocios ${moment(new Date().toString()).format("D MMMM YYYY h mm A")}`
    };
    const printOptions = { hideFooter: true, hideToolbar: true }
    const CustomExportButton = (props) => (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
        <GridPrintExportMenuItem options={printOptions} />
      </GridToolbarExportContainer>
    );
    const CustomToolbar = (props) => (
      <GridToolbarContainer {...props}>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <CustomExportButton />
      </GridToolbarContainer>
    );
    function CustomFooterTotalComponent(props) {
      return ''
    }
    return (
      <Box p={1}>
        <Card elevation={3}>
          <CardContent>
            <Box pb={2}>
              <Typography variant="h4" component="h1"><strong>Reparar Vehiculo</strong></Typography>
            </Box>
            <Paper>
              <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={data ? data : []}
                  density="compact"
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  columns={columns}
                  hideFooter
                  slots={{
                    toolbar: CustomToolbar,
                    //Footer: CustomFooterTotalComponent,
                  }}
                  onStateChange={(state) => {
                    setTotal(state.pagination.rowCount)
                  }}
                />
              </Box>
            </Paper>
          </CardContent>
        </Card>
      </Box>
    );
  };
  