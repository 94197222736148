import React, { useState } from 'react';
import { Typography, Grid, Box, CssBaseline, Button, Paper, Stack, Card, CardContent, IconButton, Container, CardActionArea } from '@mui/material';
import logo from '../../assets/img/world_of_ev_solutions.png'
import { useDispatch, useSelector } from 'react-redux';
import logo2 from '../../assets/img/fondo1.jpeg'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DifferenceIcon from '@mui/icons-material/Difference';
import HandymanIcon from '@mui/icons-material/Handyman';
import BiotechIcon from '@mui/icons-material/Biotech';
import robot from '../../assets/img/robot.png'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
export const HomePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const userLogin = useSelector(state => state.userLogin)
    const { error, loading } = userLogin

    return (
        <>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={6}
                    sx={{
                        backgroundImage: `url(${logo2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'top'
                        //backgroundPosition: 'center',
                    }}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <Button
                                sx={{ borderRadius: '2rem', textTransform: 'capitalize' }}
                                size='large'
                                color='info'
                                variant='contained'
                                onClick={() => navigate('/account/login')}
                            ><Typography variant='h6'>Inicia Sesión</Typography></Button>
                        </div>
                        <Stack direction="column" spacing={5}>
                            <Grid container spacing={5}>
                                <Grid item md={3}>
                                    <img width='100%' src={logo} alt='EV Solucion' />
                                </Grid>
                                <Grid item md={9}>
                                    <Stack direction="column" spacing={2}>
                                        <Typography component="h1" variant='h4' gutterBottom>
                                            <strong>¡Bienvenidos a EV Solución!</strong>
                                        </Typography>
                                        <div>
                                            <Typography gutterBottom color='grey' component="h2" variant='subtitle1' style={{ whiteSpace: 'pre-wrap' }}>
                                                Aquí, los soñadores, dueños de laboratorios automotrices, estudiantes y
                                                profesionales del sector automotriz encontrarán un espacio dedicado a la
                                                innovación y soluciones de vanguardia para la reparación de vehículos híbridos y
                                                eléctricos.
                                            </Typography>
                                            <Typography gutterBottom color='grey' component="h3" variant='subtitle1' style={{ whiteSpace: 'pre-wrap' }}>
                                                Nos comprometemos a ser su guía en el camino hacia una tecnología más limpia
                                                y eficiente.
                                            </Typography>
                                            <Typography gutterBottom color='grey' component="h4" variant='subtitle1' style={{ whiteSpace: 'pre-wrap' }}>
                                                ¡Únase a nosotros y eleve su laboratorio o taller al futuro!
                                            </Typography>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Stack direction="column" spacing={5}>
                                <Grid container spacing={5}>
                                    <Grid item md={3}>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Button
                                            sx={{ borderRadius: '2rem', textTransform: 'capitalize' }}
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            onClick={() => navigate('/account/signup')}
                                        > <Typography variant='h6'>Registrese, es gratis</Typography></Button>
                                    </Grid>
                                    <Grid item md={5}>
                                        <Grid container spacing={0}>
                                            <Grid item md={10}>
                                                <Button
                                                    sx={{ borderRadius: '2rem', textTransform: 'capitalize' }}
                                                    size='large'
                                                    variant='contained'
                                                    color='info'
                                                    onClick={() => navigate('/chat')}
                                                > <Typography variant='h6'>Hola soy EvBot tu experto virtual</Typography></Button>
                                            </Grid>
                                            <Grid item md={1}>
                                                <img style={{ position: 'absolute' }} width={100} height={100} alt='' src={robot} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={1}>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item md={4} xs={12}>
                                        <Card style={{ cursor: 'pointer', borderRadius: '2rem', height: 150, backgroundColor: '#E8F0FE' }} >
                                            <CardActionArea>
                                                <CardContent
                                                    onClick={() => navigate(`/manuales`)}
                                                    style={{ color: 'inherit', textAlign: 'center', textDecoration: 'none' }} >
                                                    <Box>

                                                        <DifferenceIcon color="primary" style={{ fontSize: 40 }} fontSize="large" />

                                                        <Typography align='center' variant='body2'><strong>Diagnostic Tool</strong></Typography>
                                                        <Typography align='center' variant='body2'><strong>Manuales de Herramientas</strong></Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Card style={{ cursor: 'pointer', borderRadius: '2rem', height: 150, backgroundColor: '#E8F0FE' }} >
                                            <CardActionArea>
                                                <CardContent
                                                    onClick={() => navigate(`/asesoria_tecnica`)}
                                                    style={{ color: 'inherit', textAlign: 'center', textDecoration: 'none' }} >
                                                    <Box>

                                                        <SupportAgentIcon color="primary" style={{ fontSize: 40 }} fontSize="large" />

                                                        <Typography align='center' variant='body2'><strong>Asesoria Tecnica</strong></Typography>
                                                        <Typography align='center' variant='body2'><strong>Consultoria de Negocios</strong></Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                
                                    <Grid item md={4} xs={12}>
                                        <Card
                                            onClick={() => navigate(`/reparar_vehiculo_hibrido`)}
                                            style={{
                                                borderRadius: '2rem',
                                                height: 150,
                                                backgroundColor: '#E8F0FE'
                                            }}>
                                            <CardActionArea>
                                                <CardContent
                                                    style={{ color: 'inherit', textAlign: 'center' }} >
                                                    <Box>
                                                        <HandymanIcon color="primary" style={{ fontSize: 40 }} fontSize="large" />
                                                        <Typography align='center' variant='body2'><strong>Reparar su Vehiculo</strong> </Typography>
                                                        <Typography align='center' variant='body2'><strong> Hibrido - Electrico</strong></Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Card
                                            onClick={() => navigate(`/account/signup`)}
                                            style={{
                                                borderRadius: '2rem',
                                                height: 150,
                                                backgroundColor: '#E8F0FE'
                                            }}>
                                            <CardActionArea>
                                                <CardContent
                                                    style={{ color: 'inherit', textAlign: 'center' }} >
                                                    <Box>
                                                        <BiotechIcon color="primary" style={{ fontSize: 40 }} fontSize="large" />
                                                        <Typography align='center' variant='body2'><strong>Forme Parte de la Red</strong> </Typography>
                                                        <Typography align='center' variant='body2'><strong> de Talleres y Laboratorios</strong></Typography>
                                                    </Box>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Stack>
                        {/* <Typography variant="body2" color="text.secondary" align="center" >
                            World Of EV Solutions.
                            {' '}
                            {new Date().getFullYear()}
                            {'.'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" align="center" >
                            v1.0.0
                        </Typography> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}