const initialState = {
    isLoggedIn: false,
    userInfo: null
}
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'AUTH_SUCCESS':
            return { loading: false, userInfo: action.payload }
        case 'AUTH_FAIL':
            return { loading: false, error: action.payload }
        case 'AUTH_ADD_ITEMS':
            return { ...state, userInfo: { ...action.payload, ...state.userInfo } };
        case 'AUTH_LOGOUT':
            return initialState
        default:
            return state
    }
}
export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case 'USER_LOGIN_REQUEST':
            return { loading: true }
        case 'USER_LOGIN_SUCCESS':
            return { loading: false }
        case 'USER_LOGIN_FAIL':
            return { loading: false, error: action.payload }
        case 'USER_LOGOUT':
            return {}
        default:
            return state
    }
}
export const networkingAuthorizationReducer = (state = {networking_auth:{ access: ''}}, action) => {
    switch (action.type) {
        case 'NETWORKING_AUTH_REQUEST':
            return { loading: true }
        case 'NETWORKING_AUTH_SUCCESS':
            return { loading: false, networking_auth: action.payload }
        case 'NETWORKING_AUTH_FAIL':
            return { loading: false, error: action.payload }
        case 'NETWORKING_AUTH_FAIL':
            return {}
        default:
            return state
    }
}