import {
  Box,
  LinearProgress,
  Card,
  IconButton,
  Tooltip,
  Button,
  CardContent,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { auth, db, firebase } from "../../../db/firebase";
import { useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridToolbarFilterButton,
  GridPrintExportMenuItem,
  GridToolbarQuickFilter,
  esES
} from '@mui/x-data-grid';
import { AES, enc, SHA256 } from 'crypto-js';
import { EstadoPago } from "../../../components/utils/EstadoPago";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from 'react-router-dom';
import { EstadoEtapa } from "../../../components/utils/EstadoEtapa";
let cesta1 = [
  {
    titulo: 'Obtener Firma y Observacion',
    descripcion: 'Obtener la firma, observacion y poder descargar el informe',
    precio: 1
  },
  {
    titulo: 'Asesoría Técnica Mensajeria Whatsapp',
    descripcion: 'Nos comunicaremos contigo mediante mensajes de Whatsapp',
    precio: 1
  },
  {
    titulo: 'Asesoría Técnica Llamada Telefónica',
    descripcion: 'Nos contactaremos contio mediante una llamada telefónica',
    precio: 1
  },
  {
    titulo: 'Asesoría Técnica Video Llamada',
    descripcion: 'Nos contactraemos contigo mediante video llamda',
    precio: 2
  },
]
let cesta2 = [
  {
    titulo: 'Obtener Firma y Observacion',
    descripcion: 'Obtener la firma, observacion y poder descargar el informe',
    precio: 1
  },
  {
    titulo: 'Visita Tecnica al Sitio',
    descripcion: 'solicitar visita tecnica al sitio',
    precio: 1
  },
]
let cesta3 = [
  {
    titulo: 'Obtener Firma y Observacion',
    descripcion: 'Obtener la firma, observacion y poder descargar el informe',
    precio: 1
  },
  {
    titulo: 'Trear Vehiculo al laboratorio',
    descripcion: 'Trear Vehiculo al laboratorio',
    precio: 1
  },
]
export const InformeTecnicoHibrido = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userAuth = useSelector((state) => state.userAuth);
  const { loading, userInfo } = userAuth
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  var current = new Date();
  const [startDate, setStartDate] = useState(
    new Date(current.getTime() - 172800000)
  );
  const [endDate, setEndDate] = useState(
    new Date(current.getTime() + 86400000)
  );
  useEffect(() => {
    LoadData();
  }, [startDate]);
  const LoadData = () => {
    let ref = db.collection("informe_tecnico").orderBy("created", "desc")
    ref = ref.where("user_uid", "==", userInfo.id)
    if (endDate && startDate) {
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 0, 0);
      var startfulldate = firebase.firestore.Timestamp.fromDate(startDate);
      var endfulldate = firebase.firestore.Timestamp.fromDate(endDate);
      //ref = ref.where("created", ">=", startfulldate);
      //ref = ref.where("created", "<=", endfulldate);
      //Si tiene algun doc anterior se agrega acontinuacion
      ref.onSnapshot((snapshot) => {
        const data = [
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            };
          }),
        ];
        setData(data);
      });
    }
  };
  const handleNavigate = (info) => {
    if (info.estado_pago === 'PAGADO') {
      navigate(`/order/${encodeURIComponent(AES.encrypt(info.numInfo, ''))}/`)
    } else {
      navigate(`/cart/`, {
        state: {
          ...info,
          reference: 'informe_tecnico',
          cesta: info.etapa === 1 && cesta1 || info.etapa === 2 && cesta2 || info.etapa === 3 && cesta3
        }
      })
    }
  }
  const columns = [
    { field: 'numInfo', headerName: 'Numero Informe', align: 'center', headerAlign: 'center', width: 150 },
    {
      field: 'created', headerName: 'Fecha de Registro', width: 150, align: 'center', headerAlign: 'center',
      valueGetter: ({ value }) => value && moment(new Date(value.seconds * 1000).toISOString()).format('DD/MM/YYYY h:mm:ss'),
    },
    {
      field: 'ETAPA', headerName: 'Etapa', width: 100, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <EstadoEtapa etapa={params.row.etapa} />
      }
    },
    {
      field: 'ac', headerName: 'Estado Pago', width: 125, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <EstadoPago estado_pago={params.row.estado_pago} />
      }
    },
    {
      field: 'acciones1', headerName: 'Opciones', width: 300, align: 'center', headerAlign: 'center',
      renderCell: (params) => {
        return <Stack direction='row' spacing={2}>
          <Button
            variant='contained'
            sx={{ borderRadius: 6, textTransform: 'capitalize' }}
            onClick={() => handleNavigate(params.row)}

          >{params.row.estado_pago === 'PAGADO' ? 'Ver' : 'Pagar'}</Button>
          <Tooltip title="Editar">
            <IconButton
              size="small"
              onClick={() =>
                navigate(`/informe/update/${encodeURIComponent(AES.encrypt(params.row.id, ''))}`
                )}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    },
    { field: 'asesoria', headerName: 'Tipo de Asesoria', align: 'center', headerAlign: 'center', width: 200 },

    { field: 'marca', headerName: 'Marca', align: 'center', headerAlign: 'center', width: 100 },
    { field: 'placa', headerName: 'Placa', align: 'center', headerAlign: 'center', width: 100 },

    { field: 'ano', headerName: 'Año', align: 'center', headerAlign: 'center', width: 100 },
    { field: 'vin', headerName: 'Vin', align: 'center', headerAlign: 'center', width: 100 },
    { field: 'nombres', headerName: 'Nombre del Taller', align: 'center', headerAlign: 'center', width: 200 },
    { field: 'tecnico', headerName: 'Tecnico', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'whatsapp', headerName: 'Celular', align: 'center', headerAlign: 'center', width: 125 },
    { field: 'observacion', headerName: 'Observacion', align: 'center', headerAlign: 'center', width: 150 },
    { field: 'precio', headerName: 'Precio', align: 'center', headerAlign: 'center', width: 150 },
  ];
  const csvOptions = {
    delimiter: ';',
    fileName: `INFORME TECNICO ${moment(new Date().toString()).format('DD/MM/YYYY h:mm:ss')}`
  };
  const printOptions = { hideFooter: true, hideToolbar: true }
  const CustomExportButton = (props) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  const CustomToolbar = (props) => (
    <GridToolbarContainer {...props}>
      <GridToolbarQuickFilter />
      <GridToolbarFilterButton />
      <CustomExportButton />
    </GridToolbarContainer>
  );
  return (
    <>
      <Card>
        <CardContent>
          <Box sx={{ height: 600, width: '100%', bgcolor: 'background.paper' }}>
            <DataGrid
              rows={data}

              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              columns={columns}
              hideFooter
              slots={{
                toolbar: CustomToolbar,
                loadingOverlay: LinearProgress,
              }}
              onStateChange={(state) => {
                setTotal(state.pagination.rowCount)
              }}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
