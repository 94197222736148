import { Typography, Box, Container, Button, Stack, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { InformeTecnicoHibrido } from './InformeTecnicoHibrido';
import { ButtonBack } from '../../../components/utils/ButtonBack';
export const HibridoPage = () => {
  const navigate = useNavigate();
  const params = useParams()
  useEffect(() => {
    if (params.id) {
    }
  }, [params.id]);
  return (
    <>
      <Box pt={5} ></Box>
      <Container disableGutters={window.innerWidth <= 767}>
        <Box>
          <Typography variant="h4" component="h1"><strong>Informes Tecnicos</strong></Typography>
        </Box>
        <Stack my={2} direction="row" >
          {/* <ButtonBack /> */}
          <Button
            sx={{ borderRadius: '.3rem', textTransform: 'capitalize' }}
            size='large'
            variant="contained"
            onClick={() => navigate('/informe/crear')}
          >
            <Typography variant='h6'>Crear Informe</Typography>
          </Button>
        </Stack>
        <InformeTecnicoHibrido />
      </Container>
    </>
  )
}