import React, { useState } from "react";
import {
    IconButton, Button,
    DialogActions, DialogContent, DialogTitle, Dialog, InputLabel, Tooltip, TextField,
    Stack,
    FormControl,
    Select,
    MenuItem,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { db } from "../../../db/firebase";
import Swal from "sweetalert2";
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es'
export const BtnUsuariosOpciones = ({ props }) => {
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false)
    const [verified, setVerified] = useState(false)
    const [rol, setRol] = useState('')
    const [nombres, setNombre] = useState('')
    const [credits, setCredits] = useState(0)
    const handleSubmit = async () => {
        setOpen(false)
        db.collection('usuarios').doc(data.id).update({
            nombres: nombres,
            verified: verified ? verified : false,
            credits: credits ? parseInt(credits) : 0,
            roles: [rol]
        }).then(() => {
            Swal.fire({
                icon: "success",
                showConfirmButton: false,
                text: "La informacion se actualizo"
            })
        })
    }
    const onData = () => {
        setData(props)
        setRol(props.roles[0])
        setVerified(props.verified ? props.verified : false)
        setNombre(props.nombres)
        setCredits(props.credits ? props.credits : 0)
        setOpen(true)
    }
    const handleDelete = async () => {
        let id = props.id
        Swal.fire({
            text: "¿Desea eliminar el informe de forma permanente?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Sí, eliminar"
        }).then((result) => {
            console.log(data.id)
            if (result.isConfirmed) {
                db.collection('informe_tecnico').doc(id).delete().then(() => {
                    Swal.fire({ icon: "success", showConfirmButton: false, text: "Tu informe tecnico fue eliminado", })
                })
            }
        });
    }
    return (
        <>
            <Tooltip title="Editar">
                <IconButton size="small" onClick={() => onData()}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} fullWidth maxWidth='xs'>
                <DialogTitle>
                    <strong>Datos de Cuenta</strong>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <div>
                            <InputLabel>
                                Nombres y Apellidos
                            </InputLabel>
                            <TextField
                                fullWidth
                                value={nombres}
                                onChange={(e) => setNombre(e.target.value)}
                            />
                        </div>
                        <div>
                            <InputLabel>Roles de cuenta</InputLabel>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={rol}
                                    onChange={(e) => setRol(e.target.value)}
                                >
                                    <MenuItem value="admin">admin</MenuItem>
                                    <MenuItem value="taller">taller</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <InputLabel>Habilitar Cuenta</InputLabel>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    sx={{
                                        '& legend': { display: 'none' },
                                        '& fieldset': { top: 0 },
                                    }}
                                    value={verified}
                                    onChange={(e) => setVerified(e.target.value)}
                                >
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <InputLabel>
                                Creditos:
                            </InputLabel>
                            <TextField
                                fullWidth
                                type='number'
                                value={credits}
                                onChange={(e) => setCredits(e.target.value)}
                            />
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSubmit()}
                        variant="contained"
                    >
                        Guardar informacion
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
