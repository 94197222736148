import React, { useState, useRef } from "react";
import {
    IconButton, Button, Box,
    DialogActions, DialogContent, DialogTitle, Dialog,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Tabs,
    Tab,
    AppBar
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { PDFViewer } from '@react-pdf/renderer';
import 'moment/locale/es'
import PDFOrdenTrabajo from "./PDFOrdenTrabajo";
import SearchIcon from '@mui/icons-material/Search';
import { DescargarOrdendPDF } from "./DescargarInformePDF copy";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    {children}
                </Box>
            )}
        </div>
    );
}
export const ViewOrderButton = ({ props }) => {
    const [data, setData] = useState('')
    const [open, setOpen] = useState(false);
    const onData = () => {
        setData(props)
        setOpen(true)
    }
    return (
        <>
            <IconButton size="small" onClick={() => onData()}>
                <SearchIcon />
            </IconButton>
            <Dialog open={open} fullWidth maxWidth="md">
                <DialogTitle>
                    <strong>Orden de Trabajo</strong>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <DescargarOrdendPDF informe={data} />
                    <PDFViewer
                        width='100%'
                        height={1200}
                    >
                        <PDFOrdenTrabajo informe={data} />
                    </PDFViewer>
                </DialogContent>
            </Dialog >
        </>
    );
};
