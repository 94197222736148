import {
    Box, Grid, Button
} from "@mui/material"
import React, { useEffect, useState } from "react"
import InputValidation from "../../components/utils/InputValidation"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from 'react-redux'
import { auth, firebase, storage, db } from '../../db/firebase'
import { useParams, useNavigate } from 'react-router-dom'
import { UpdateFileManual } from "./UpdateFileManual"
import Compressor from 'compressorjs';
export const NuevoManual = () => {
    const params = useParams()
    let navigate = useNavigate()
    //Estado de crear nueva solicitud
    const userAuth = useSelector(state => state.userAuth)
    const { userInfo } = userAuth
    const [loading, setLoading] = useState(false)
    const [nombre, setNombre] = useState({ campo: "", valido: true });
    const [descripcion, setDescripcion] = useState({ campo: "", valido: true });
    const [valor, setValor] = useState({ campo: "", valido: true });
    const [fileArray, setFileArray] = useState([])

    const submitHandler = async (e) => {
        setLoading(true);
        try {
            const solicitudesDb = await db.collection("manuales").orderBy("created", "desc").limit(1).get();
            var numeroSolicitud = 1;
            if (solicitudesDb.size > 0) {
                const solicitudes = solicitudesDb.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                const solicitud = solicitudes[0];
                numeroSolicitud = solicitud.id + 1;
            }
            const uidSolicitud = `MANUAL-${numeroSolicitud}`;
            await db.collection('manuales').doc(`${numeroSolicitud}`).set({
                id: numeroSolicitud,
                estado: 1,
                nombre: nombre.campo,
                descripcion: descripcion.campo,
                valor: valor.campo,
                numeroSolicitud: numeroSolicitud,
                user_uid: auth.currentUser.uid,
                nombres: userInfo.nombres,
                created: firebase.firestore.FieldValue.serverTimestamp()
            });
            console.log(numeroSolicitud, ' docRef');
            const info = {
                id: numeroSolicitud,
                uidSolicitud: uidSolicitud
            };
            const uploadPromises = fileArray.map(async (item) => {
                try {
                    const pictureRef = storage.ref().child('manuales').child(`${info.uidSolicitud}`).child(item.name);
                    const letreroUpload = pictureRef.put(item.file);
                    letreroUpload.on("state_changed", (snapshot) => {
                        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case firebase.storage.TaskState.PAUSED:
                                console.log('Upload is paused');
                                break;
                            case firebase.storage.TaskState.RUNNING:
                                console.log('Upload is running');
                                break;
                        }
                    }, (error) => {
                        Swal.fire({ icon: 'error', text: error, });
                    }, async () => {
                        const downloadURL = await letreroUpload.snapshot.ref.getDownloadURL();
                        console.log('File available at', downloadURL, ' ', info.id);
                        const updateData = {};
                        updateData[item.name] = downloadURL;
                        db.collection('manuales').doc(`${info.id}`).update(updateData);
                    });
                } catch (error) {
                    Swal.fire({ icon: "warning", text: `Compressor: ${error}`, });
                    return null;
                }
            });
            const uploadResults = await Promise.all(uploadPromises);
            const allUploadsSuccessful = uploadResults.every((result) => result !== null);
            if (allUploadsSuccessful) {
                // Todas las subidas fueron exitosas, mostrar el alert
                //alert('Subida de archivos completada con éxito');
                navigate(`/carga_manuales/1`)
                setLoading(false);
                Swal.fire({ icon: "success", showConfirmButton: false, text: "El registro se ha creado exitosamente.", })
            } else {
                setLoading(false);
                alert('Error en la subida de archivos, por favor contactar con soporte');
            }
        } catch (error) {
            setLoading(false);
            alert(error);
        }
    };
    return (
        <Box p={1}>
                <Grid container spacing={5}>
                    <Grid item md={4} xs={12}>
                        <InputValidation
                            estado={nombre}
                            cambiarEstado={setNombre}
                            label="Nombre del Manual"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        />
                        <InputValidation
                            estado={descripcion}
                            cambiarEstado={setDescripcion}
                            label="Descripcion del Manual"
                            name="name"
                            helperText="Minimo 8 caracteres"
                        />
                        <InputValidation
                            estado={valor}
                            cambiarEstado={setValor}
                            patterns={/^\d+(\.\d{1,2})?$/}
                            label="Valor del Manual"
                            name="name"
                            helperText="Formato correcto 00.00"
                        />
                        <UpdateFileManual
                            setDataArray={setFileArray}
                            nameFile="Portada"
                        />
                        <UpdateFileManual
                            setDataArray={setFileArray}
                            nameFile="Manual"
                        />
                        <Button
                            type="submit"
                            color="secondary"
                            variant="contained"
                            onClick={() => submitHandler()}
                            disabled={loading || !valor.campo || !nombre.campo || !descripcion.campo}
                        > Enviar nueva solicitud
                        </Button>
                    </Grid>
                </Grid>
           
        </Box >
    );
};