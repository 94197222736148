import React, { useEffect, useState } from 'react';
import {
    Typography, InputLabel, MenuItem, CardContent, Select,
    Card, Box, Container, DialogContent, DialogActions, Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import InputValidation from '../../components/utils/InputValidation';
import image from '../../assets/img/world_of_ev_solutions.png'
import { db, firebase } from '../../db/firebase'
import Swal from 'sweetalert2';
export const RepararVehiculoHibrido = () => {
    const [pais, setPais] = useState('')
    const [nombres, setNombres] = useState({ campo: '', valido: null })
    const [whatsapp, setWhatsapp] = useState({ campo: '', valido: null })
    const [correo, setCorreo] = useState({ campo: '', valido: null })
    const [asesoria, setAsesoria] = useState('')
    const [loading, setLoading] = useState(false)
    //list
    const [list_assesorias, setListAssesorias] = useState([])
    const [list_pais, setListPais] = useState([])
    useEffect(() => {
        load_list_asistencias()
        load_pais()
    }, [])
    const submitHandler = () => {
        setLoading(true)
        db.collection('reparar_vehiculo_hibrido').orderBy('created', 'desc').limit(1).get()
            .then((transaccionesDb) => {
                let id_transaccion = 1
                if (!transaccionesDb.empty) {
                    const ultimoDocumento = transaccionesDb.docs[0]
                    id_transaccion = ultimoDocumento.data().id
                }
                const nuevoID = id_transaccion + 1
                db.collection(`reparar_vehiculo_hibrido`).doc(`${nuevoID}`).set({
                    id: nuevoID,
                    pais: pais,
                    nombres: nombres.campo.toUpperCase(),
                    whatsapp: whatsapp.campo,
                    correo: correo.campo,
                    taller_laboratorio: asesoria.toUpperCase(),
                    created: firebase.firestore.FieldValue.serverTimestamp(),
                }).then(() => {
                    Swal.fire({ icon: "success", showConfirmButton: false, text: "Le informamos que su solicitud de asesoría técnica ha sido recibida correctamente.", })
                    setLoading(false)
                })
            })
    }
    const load_list_asistencias = () => {
        db.collection(`tipos_asesorias`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListAssesorias(arrayMessage)
            })
    }
    const load_pais = () => {
        db.collection(`system/settings/pais`)
            .onSnapshot((querySnapshot) => {
                const arrayMessage = querySnapshot.docs.map(item => { return { ...item.data(), id: item.id } })
                setListPais(arrayMessage)
            })
    }
    return (
        <>
            <Container maxWidth='md'>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: 25,
                    paddingBottom: 10
                }}>
                    <RouterLink to='/'>
                        <img width={200} src={image} alt='' />
                    </RouterLink>
                </div>
                <Box pl={2} pb={2}>
                    <Typography gutterBottom align='center' variant='h5'>
                        <strong>Reparar su Vehiculo Hibrido - Electrico</strong>
                    </Typography>
                </Box>
                <Card elevation={2}>
                    <CardContent>
                        <InputLabel>Seleccione Lugar</InputLabel>
                        <Select
                            fullWidth
                            size='small'
                            value={pais}
                            onChange={(e) => setPais(e.target.value)}
                        >
                            {list_pais?.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                            ))}
                        </Select>
                        <Box pt={3}></Box>
                        <InputValidation
                            estado={nombres}
                            cambiarEstado={setNombres}
                            type="email"
                            label="Nombres y Apellidos"
                            helperText="Solo letras"
                            patterns={/^[a-zA-Z\s]+$/}
                        />
                        <InputValidation
                            estado={whatsapp}
                            cambiarEstado={setWhatsapp}
                            label="Whatsapp"
                            helperText="Minimo 10 digitos"
                            patterns={/^\d{10}$/}
                        />
                        <InputValidation
                            estado={correo}
                            cambiarEstado={setCorreo}
                            type="email"
                            label="Correo electronico"
                            helperText="Solo correo electronico"
                            patterns={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                        />
                        <InputLabel>Red de Talleres y Laboratorios</InputLabel>
                        <Select
                            fullWidth
                            size='small'
                            value={asesoria}
                            onChange={(e) => setAsesoria(e.target.value)}
                        >
                            {list_assesorias?.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
                            ))}
                        </Select>
                    </CardContent>
                </Card>
                <DialogActions>
                    <Box pr={2}>
                        <Button
                            component={RouterLink}
                            to='/'
                        >Volver</Button>
                    </Box>
                    <Button
                        variant='contained'
                        color='secondary'
                        disabled={
                            loading ||
                            !correo.campo || !correo.valido || !asesoria || !pais ||
                            !nombres.campo || !nombres.valido ||
                            !whatsapp.campo || !whatsapp.valido
                        }
                        onClick={() => submitHandler()}
                    >Enviar Informacion</Button>
                </DialogActions>
            </Container>
        </>
    )
}