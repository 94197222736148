import React, { useState } from 'react';
import { Typography, Grid, Box, CssBaseline, Button, Paper, Stack, Card, CardContent, IconButton } from '@mui/material';
import Alert from '@mui/material/Alert';
import InputValidation from '../../components/utils/InputValidation';
import logo from '../../assets/img/world_of_ev_solutions.png'
import { login } from '../../redux/actions/account/authActions'
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import logo2 from '../../assets/img/fondo1.jpeg'
import { Link as RouterLink } from 'react-router-dom';
import DifferenceIcon from '@mui/icons-material/Difference';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HandymanIcon from '@mui/icons-material/Handyman';
import BiotechIcon from '@mui/icons-material/Biotech';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { db } from '../../db/firebase';
export const LoginPage = () => {
    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { error, loading } = userLogin
    const [email, setEmail] = useState({ campo: '', valido: true })
    const [password, setPassword] = useState({ campo: '', valido: null })
    const submitHandler = (e) => {
        e.preventDefault()
        if (email.valido === true && password.valido === true) {
            dispatch(login({
                'email': email.campo,
                'password': password.campo
            }))
        }
    }
    const button1 = () => {
        let ref = db.collection("solicitudes_equifax")
        ref.onSnapshot((snapshot) => {
            const data = [
                ...snapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        id: doc.id,
                    };
                }),
            ];
            console.log(data)
            data.map((item, index) => {
                setTimeout(() => {
                  const myHeaders = new Headers();
                  myHeaders.append("Content-Type", "application/json");
                  const raw = JSON.stringify({
                    "cedula": item.cedula,
                    "centro_costo": "userInfo?.vendedor?.centro_costo"
                  });
                  const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                  };
                  fetch("https://us-central1-sistema-gestion-intercommerce.cloudfunctions.net/api/equifax25", requestOptions)
                    .then((response) => response.text())
                    .then((result) => console.log(result))
                    .catch((error) => console.error(error));
                }, 9000)
              })
        });
        
    }
    return (
        <>
      
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={4}
                    sx={{
                        backgroundImage: `url(${logo2})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'top'
                        //backgroundPosition: 'center',
                    }}
                >
                </Grid>
                <Grid item xs={12} sm={8} md={8} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                        }}
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingBottom: 20
                        }}>
                            <img width={200} src={logo} alt='' />
                        </div>
                        <Box pb={2}>
                            <Typography variant='h4' align='center' gutterBottom>
                                <strong>¡Bienvenidos a EV Solución!</strong>
                            </Typography>

                        </Box>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <form onSubmit={submitHandler}>

                            <InputValidation
                                estado={email}
                                cambiarEstado={setEmail}
                                type="email"
                                label="Correo electrónico"
                                name="email"
                            //helperText="Sólo correos asociados a la institución '@ups.edu.ec'."
                            //patterns={/^[a-zA-Z0-9_.+-]+@+(est\.)?(ups.edu.ec)/}
                            //disabled={loading}
                            />

                            <InputValidation
                                estado={password}
                                cambiarEstado={setPassword}
                                type="password"
                                label="Contraseña"
                                name="password"
                                helperText="Minimo 8 caracteres"
                                patterns={/^.{8,30}$/}
                            //disabled={loading}
                            />

                            {!loading ?
                                <Button variant="contained" fullWidth disableElevation
                                    disabled={!password.campo || !email.campo || !email.valido || !password.valido}
                                    type="submit"
                                >
                                    Iniciar sesión
                                </Button>
                                :
                                <LoadingButton
                                    fullWidth
                                    loading
                                    variant="contained"
                                    disabled
                                >Cargando</LoadingButton>
                            }

                            <Box pt={2} pb={5}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <RouterLink style={{ textDecoration: 'none', color: 'inherit' }} to='/' >
                                            ¿Has olvidado tu contraseña?
                                        </RouterLink>
                                    </Grid>
                                    <Grid item>
                                        <RouterLink style={{ textDecoration: 'none', color: 'inherit', }} to='/account/signup' >
                                            {"¿No tienes una cuenta? Create Cuenta"}
                                        </RouterLink>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}